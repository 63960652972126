.btn {
    position: relative;
    white-space: nowrap;
    font-family: var(--sans-serif);
    font-weight: 300;
    font-size: 18px;
    overflow: hidden;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
}

.btn:hover {
    color: white;
    box-shadow: var(--box-shadow);
    transition: all 0.1s;
    -webkit-transition: all 0.1s;
}

.rect-btn {
    background-color: var(--dark-blue);
    width: 175px;
    margin: auto;
    padding: 1.25vmin;
    border-radius: 4px;
    color: #fff;

}

.rect-btn:hover {
    background-color: var(--violet);
}

.rounded-btn {
    background: var(--dark-blue);
    width: calc(200px + (6 * ((100vw - 320px) / 680)));
    padding: 5px 20px 5px 20px;
    margin: 5px;
    border: none;
    border-radius: 20px;
    color: white;
}

.rounded-btn:hover {
    background-color: var(--violet);
}
@use 'main';
@use 'abstracts/mixins' as m;
@use 'abstracts/functions';

.my-masonry-grid {
  display: -webkit-box;
  /* Not needed if autoprefixing */
  display: -ms-flexbox;
  /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px;
  /* gutter size offset */
  width: auto;
}
.splash-text {
  margin-left:auto;
  margin-right: auto;
  margin-bottom: 20vh;
}
.fake-link:hover {
  cursor: pointer;
  text-decoration: underline;
}

.my-masonry-grid_column {
  padding-left: 30px;
  /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column {
  /* change div to reference your elements you put in <Masonry> */
  background: grey;
  margin-bottom: 30px;
}

:root {

  --darkest-blue: #21387a;
  --dark-blue: #4061d6;
  --blue: #598cfa;
  --light-blue: #59abfa;
  --lightest-blue: #d6e5f5;

  --dark-violet: #1700eb;
  --violet: #7366f0;
  --light-violet: #abb6eb;

  --dark-yellow: #c48404;
  --yellow: #fcb529;
  --light-yellow: #fece77;

  --dark-green: #3f9c99;
  --green: #66c8b6;
  --light-green: #77e4c6;

  --dark-berry: #9e1b4a;
  --berry: #d54d68;
  --light-berry: #e57c7e;

  --border-radius: 4px;
  --box-shadow: 0 3px 10px 0 rgba(59, 64, 66, 0.5);
  --box-shadow-light: 0 3px 10px 0 rgba(59, 64, 66, 0.1);
  --color: white;

  --alt-color: #f2f4fc;
  --hover-color: black;
  --dash-width: 300px;
  --dash-height: 300px;
  --icon-size: 20pt;
  --cite-size: 14pt;

  --sans-serif: 'Outfit', sans-serif;
  --serif: 'Roboto Serif', serif;
  --header-title-size: calc(24px + (6 * ((100vw - 320px) / 680)));
  --section-title-size: calc(32px + (6 * ((100vw - 320px) / 680)));
  --sub-title-size: calc(22px + (6 * ((100vw - 320px) / 680)));

  --nav-padding: 10px;
  --nav-logo-max: 60px;
  --logo-max: 100px;

  
  --background-blue: #abb8c0;
}

body,
html {
  height: 100%;
  overflow: hidden;
  background-color: var(--alt-color);
}

body {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

body::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 700px) {
  body {
    font-size: 18px;
  }
}

.friend-icon {
  font-size: 1.35em;
  margin-left: 10px;
}

a {
  text-decoration: none;
}

.alert-overlay {
  position: fixed;
  inset: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  min-width: 100%;
  background-color: rgb(0, 0, 0, .33);
  overflow: auto;
  z-index: 999;
  padding: 40px 20px 20px;
}

.toast-overlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  min-width: 100%;
  z-index: 999;
  padding: 40px 20px 20px;
}

.toast {
  width: 100%;
  min-height: 40px;
  font-size: 18px;
  z-index: 10000;
  position: absolute;
  top: 100px;
  bottom: 0;
  right: 0;
}

.modal-overlay {
  position: fixed;
  inset: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  min-width: 100%;
  background-color: rgba(37, 37, 37, 0.75);
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 1100;
  padding: 40px 20px 20px;
}

.alert-card {
  width: 600px;
  max-width: 100%;
  padding-bottom: 5px;
  height: fit-content;
  max-height: 80vh;
  min-height: 150px;
  flex-direction: column;
  position: fixed;
  background-color: rgb(255, 255, 255, 1);
  display: flex;
  align-content: center;
  justify-content: center;
  text-align: left;
  border-radius: 4px;
  box-shadow: var(--box-shadow);
  z-index: 1100;
  overflow: auto;
}

.alert-body {
  font-size: calc(18px + (2 * ((100vw - 320px) / 680)));
  margin-bottom: 20px;
}

.alert-button {
  background-color: var(--dark-blue);
  font-size: calc(16px + (2 * ((100vw - 320px) / 680)));
  width: 33%;
  min-width: 100px;
  color: white;
  padding: 2px 10px 2px 10px;
  text-align: center;
  border-radius: 4px;
  margin-top: 5px;
}

.alert-button:hover {
  background-color: var(--violet);
}

#dismiss-alert {
  background: var(--dark-berry);
}

#dismiss-alert:hover {
  background: var(--berry);
}

.alert-footer {
  padding-top: 5px;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  border-width: 2px;
  border-top: solid rgb(47, 79, 79, 0.15);
  width: 100%;
}

.modal-card {
  width: fit-content;
  max-width: 95%;
  height: fit-content;
  max-height: 90%;
  position: absolute;
  flex-direction: column;
  margin-top: -50px;
  padding-bottom: 20px;
  display: flex;
  align-content: center;
  text-align: left;
  border-radius: 4px;
  box-shadow: var(--box-shadow);
  z-index: 1000;
  overflow-y: auto;
}

#modal-card-light {
  background-color: var(--color);
  color: var(--darkest-blue);
}

#modal-card-light h2 {
  color: var(--darkest-blue);
}

#modal-card-dark {
  background-color: var(--darkest-blue);
  color: var(--color);
}

#modal-card-dark h2 {
  color: var(--light-violet);
}

@media screen and (max-width: 500px) {
  .modal-card {
    width: fit-content;
    max-width: 100%;

  }
}

.loading-container {
  background-color: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  z-index: 1200;
}

.loading-msg {
  display: block;
  text-align: center;
  font-size: 18pt;
  color: gray;
}

@media screen and (max-height: 575px) {
  .loading-msg {
    display: none;
  }
}

.toast {
  background-color: #e57c7e;
  width: 100vw;
  position: fixed;
  inset: 0;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 1200;
  padding: 40px 20px 20px;
}

.centered-row {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
  justify-content: center;
  margin: auto;
}

.cj-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.center-logo {
  display: flex;
  justify-content: center;
  margin-top: -30%;
}

.horizontal-logo {
  display: flex;
  justify-content: center;
}

.progress-bar {
  position: relative;
  width: 700px;
  max-width: 90%;
  height: 15px;
  /*   border: 2px solid var(--dark-blue); */
  border-radius: 10px;
  overflow: hidden;
}

.progress-bar-inner {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.analyze-progress {
  width: 50%;
  align-items: center;
  margin: auto auto 20px auto;
}

.analyze-wrapper {
  height: 100%;
}

.white-link {
  color: white;
}

.panel-wrapper {
  text-align: center;
  margin: auto;
  padding-top: 5vh;
}

.ws-number {
  font-size: 4em;
  font-weight: 600;
  fill: var(--dark-blue);
}

.doc-title {
  background-color: white;
  color: var(--dark-blue);
  border-radius: 5px;
  font-size: 1.75em;
  font-weight: 600;
}

.ws-text {
  font-size: 2em;
  font-weight: 400;
  fill: var(--dark-blue);
}

.expedite-title-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--alt-color);
  background-color: var(--dark-blue);
  font-family: var(--sans-serif);
  border-radius: 3px;
  padding: 1vmin;
  margin: 1vmin;
}

.expedite-title {
  font-size: 1.75em;
}

.expedite-sub-title {
  font-size: 1.25em;
}

.tooltip-icon {
  margin-left: 5px;
  font-size: 12px;
  margin-bottom: 8px;
}

.tile-slider-rel-wrapper {
  position: relative;
  display: inline;
}


.tile-slider-arrow-wrapper {
  display: flex;
  direction: row;
  align-items: center;
}

.tile-slider {
  display: flex;
  flex-direction: row;
  height: fit-content;
}

.tile-slide-arrow {
  color: darkgray;
  opacity: 0.5;
  font-weight: 600;
  font-size: 32px;
  padding: 5px;
}

.tile-slider-pause {
  position: absolute;
  color: var(--blue);
  font-weight: 600;
  font-size: 128pt;
  bottom: 40%;
  right: 80%;
}

.tile-slider-pause:hover {
  cursor: pointer;
  color: var(--darkest-blue);
}

.tile-slider-controls-wrapper {
  border: 1px solid darkgray;
  border-radius: 40px;
  padding: 0 0 10px 0;
  width: 30%;
  margin: auto;
  color: darkgray;
  font-size: 32px;
}

.tile-slider-controls-wrapper:hover {
  box-shadow: var(--box-shadow);
  border: none;
  background-color: #fff;
  color: var(--dark-blue);
}

.tile-icon {
  margin-left: 5px;
  margin-right: 5px;
}

.tile-icon:hover {
  color: var(--violet);
}

.tile-slide-arrow:hover {
  cursor: pointer;
  color: var(--violet);
}

.active-tile-page {
  color: var(--light-blue);
  margin: 0.5vw;
}

.active-tile-page:hover {
  cursor: pointer;
  color: var(--darkest-blue);
}

.dormant-tile-page {
  color: lightgray;
  margin: 0.5vmin;
}

.dormant-tile-page:hover {
  cursor: pointer;
  color: white;
}

.tile-tooltip {
  font-family: var(--sans-serif);
  font-weight: 600;
  font-size: 18;
  color: var(--dark-violet);
}

.preview-tile {
  padding: 1vw;
  margin: 1vw;
  border: white solid 3px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: var(--box-shadow);
  overflow-y: clip;
}

.preview-tile:hover {
  cursor: pointer;
}

.preview-tile-title {
  color: var(--darkest-blue);
  background-color: white;
  font-weight: 600;
  padding: 5px;
}

.gender-tile-title {
  text-align: center;
  padding: 0 4px 0 4px;
  margin: 20px auto 20px auto;
  font-size: 1rem;
  font-weight: 800;
  background-color: var(--dark-blue);
  border-radius: 4px;
  color: #fff;
}

.gender-tile-data {
  margin: calc(50% - 6.5rem) auto 1%;
  width: fit-content;
  font-weight: 800;
  font-size: 6.5rem;
  color: var(--blue);
}

.gender-alt-data {
  width: fit-content;
  font-weight: 800;
  font-size: 2.5rem;
  margin: 20px auto 20px auto;
  color: var(--blue);
}

.gender-alt-tile {
  width: fit-content;
  margin: auto;
  font-weight: 800;
  font-size: 4.5rem;
  color: var(--blue);
}

.gender-alt-legend {
  display: inline-flex;
  flex-direction: column;
  margin: auto;
  text-align: left;
  font-size: 1rem;
  font-weight: 600;
  color: var(--darkest-blue);
}

@media screen and (min-width: 1900px) {
  .gender-alt-data {
    width: fit-content;
    margin: auto;
    font-weight: 800;
    font-size: 5.5rem;
    color: var(--blue);
  }

  .gender-alt-tile {
    margin: auto;
    width: fit-content;
    color: var(--blue);
  }

  .gender-alt-legend {
    display: inline-flex;
    flex-direction: column;
    margin: auto;
    text-align: left;
    font-size: 1rem;
    font-weight: 600;
    color: var(--darkest-blue);
  }
}

.gender-tile-wrapper {
  justify-content: space-between;
  position: relative;
  margin-bottom: 5%;
}



/* .gender-alt-wrapper {
  justify-content: space-evenly;
  position: relative;
  margin-bottom: 5%;
} */

.gender-tile-container {
  margin: auto;
}

.gender-tile-legend {
  display: inline-flex;
  flex-direction: column;
  padding-bottom: 8%;
  margin-top: 10%;
  margin-left: -10%;
  text-align: left;
  font-size: 1rem;
  font-weight: 600;
  color: var(--darkest-blue);
}


.tile-layout {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: auto;
  height: fit-content;
}

.tile-data-mode {
  width: 18vw;
  min-width: 270px;
  max-width: 500px;
  height: 20vw;
  min-height: 300px;
  max-height: 550px;
  padding: 1vw;
  margin: 1vw;
  border: white solid 3px;
  border-radius: 5px;
  background-color: var(--light-violet);
  box-shadow: var(--box-shadow);
}

.tile-data-mode:hover {
  cursor: pointer;
}

.filler {
  height: 55vh;
}

.full-data-mode {
  width: 85%;
  padding: 1vw;
  margin: 1vw;
  border-radius: 5px;
  box-shadow: var(--box-shadow);
}


.tile-data-title {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  height: 3.5vh;
  color: var(--darkest-blue);
  background-color: white;
  font-weight: 600;
  font-size: 20pt;
  padding: 5px;
  border-radius: 5px;
  box-shadow: var(--box-shadow);
}

.stacked {
  display: flex;
  flex-direction: column;
}

.tile-title-mode {
  height: 7.5vw;
  width: 7.5vw;
  line-height: 7.5vw;
  color: white;
  background-color: var(--darkest-blue);
  border-radius: 5px;
  align-items: center;
  align-content: center;
  text-align: center;
  border: 3px solid white;
}

.tile-title-mode:hover {
  background-color: var(--light-blue);
}

.tile-title-mode-active {
  height: 7.5vw;
  width: 7.5vw;
  line-height: 7.5vw;
  color: var(--darkest-blue);
  background-color: white;
  border-radius: 5px;
  align-items: center;
  align-content: center;
  text-align: center;
  border: 3px solid white;
}

.tile-title-mode-active:hover {
  background-color: var(--light-blue);
}


.tile-title-mode:hover {
  cursor: pointer;
}

.data-layout {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;
}

.tile-menu {
  display: flex;
  flex-direction: column;
}

.account-settings-wrapper {
  padding: 1vmin;
}

.arbsized {
  width: 600px;
}

.fullsized {
  width: 75vw;
  height: auto;
}

.topic-list {
  padding: 1vmin;
  height: 82.5%;
  overflow: auto;
  width: 100%;
}

#card-sub-title {
  color: var(--darkest-blue);
  background-color: white;
  font-weight: 600;
  padding: 5px;
  font-size: 16px;
}

.padded {
  padding: 2px;
}

.selection-panel-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.selection-panel {
  background-color: var(--lightest-blue);
  display: flex;
  flex-direction: row;
  margin: 1vmin;
  border-radius: 3px;
  padding: 1vmin;
  max-height: 75vh;
  max-width: 75vw;
  overflow: auto;
}

.selection-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
}

.tg-spacer {
  margin: 1vh;
  height: 82.5%;
}

.wc-terms {
  padding-top: 15vh;
}

.right-five-px {
  margin-right: 10px;
}

.full-size-graph {
  height: 50vh;
  width: 50vh;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1vh;
  padding: 1vmin;
}

.ws-padding {
  margin-top: 1vh;
  margin-left: auto;
  margin-right: auto;
  padding: 1vmin;
  width: 100%;
  max-width: 1200px;
}

.wc-scaling-svg-container {
  position: relative;
  height: 0;
  width: 100%;
  padding: 0;
  padding-bottom: 90%;
  /* override this inline for aspect ratio other than square */
}


.wcw-panel {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
}

.wcw {
  display: flex;
  flex-direction: row;
  padding: 1vw;
  background-color: white;
  border-radius: 5px;
  margin: 1vmin;
  color: var(--dark-blue);
  box-shadow: 5px 5px var(--light-blue);
}

.search-in-results {
  width: 90%;
  margin-top: 3vh;
  margin-bottom: 3vh;
  margin-right: auto;
  margin-left: auto;
}

.wc-scaling-svg {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}

.filled {
  width: 100%;
}

.percentile {
  display: flex;
  flex-direction: column;

}

.percentile-selector {
  margin: 1vmin;
}

.percentile-item {
  display: flex;
  direction: row;
  align-items: top;
  justify-content: center;
}

.portal-list-item {
  display: flex;
  direction: column;
  align-items: center;
  justify-content: space-between;
}

.portal-list-item-element {
  max-width: 75%;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.shared-drive-options {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 3vmin;
  margin: auto;
  width: 800px;
  max-width: 100%;
  min-height: 60px;
}

.shared-drive-item {
  margin: 10px;
}


.removable-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}


.editor-checker {
  padding: 10px;
}

.editor-checker-title {
  font-family: var(--sans-serif);
  font-size: 22px;
  font-weight: 600;
  color: var(--dark-blue);
}

.editor-checker-table {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: top;
  justify-content: space-around;
}

.editor-checker-table-item {
  margin: 1vmin;
}

.sneaky-scroll::-webkit-scrollbar {
  display: none;
}

.sneaky-scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* -----Basic components----- */
.main {
  flex-direction: column;
  min-height: 100vh;
  min-width: 100vw;
  position: relative;
  overflow: auto;
  background: #fff;
}

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
  margin: auto;
  background: var(--alt-color);
  min-width: 100vw;
  min-height: 100vh;
  height: 100%;
  padding: 10%;
}

.paper-search-container {
  justify-content: center;
  text-align: center;
  background: var(--alt-color);
  width: 600px;
  max-width: 100%;
  margin: auto;
  position: absolute;
  top: 30vh;
  left: 0;
  right: 0;
  height: fit-content;
}

@media screen and (max-width: 1000px) {
  .container {
    padding: 20% 0;
  }
}

.main-bg {
  background: var(--alt-color);
  padding: 5%;
  height: fit-content;
  min-width: 90vw;
  position: relative;
  min-height: 70vh;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
}

.submissions-title {
  color: white;
  background-color: var(--dark-blue);
  width: 50%;
  font-size: 1.5rem;
  font-weight: 600;
  border-radius: 5px;
  margin: auto;
  padding: 0.5vmin;
}

.large-table-wrapper {
  width: 100%;
}

.st0 {
  fill: #263776;
}

.st1 {
  fill: #648AF1;
}

.st2 {
  fill: #919DDA;
}

.st3 {
  fill: #fff;
}

.st4 {
  fill: #4760CE;
}

.st5 {
  fill: #6EA8F3;
}

.st6 {
  fill: #D8E4F2;
}

.st7 {
  fill: #588DFB;
}

.st8 {
  fill: #4061D7;
}

.st9 {
  fill: #22377A;
}

/* -----Splash Page----*/

.animation-wrapper {
  min-height: 55vh;
  min-width: 80vw;
}

.display-wrapper {
  min-width: 30vw;
  max-width: 100ch;
  display: inline-flex;
  flex-direction: column;
  margin: 10px;
}

.display-card {
  height: fit-content;
  background: var(--color);
  padding: 2vmax;
  margin: 1vmax;
  text-align: left;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
}

.display-name {
  text-align: center;
  margin-bottom: 10px;
  font-size: 28px;
  border-radius: var(--border-radius);
  background-color: var(--darkest-blue);
  color: var(--color);
  padding: 15px;
}

#display-analyze {
  display: flex;
  flex-direction: row;
  font-size: 20px;
  text-align: center;
}

#display-filters {
  width: 100%;
  text-align: center;
  font-size: 20px;
}

.display-text {
  padding: 10px;
  max-width: 50ch;
}

/* ------ Wave (Loader) ------ */
.center {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
}

.wave {
  width: 5px;
  height: 100px;
  background: linear-gradient(45deg, #046abd, #315c7c);
  margin: 10px;
  animation: wave 1.5s linear infinite;
  border-radius: 20px;
}

.wave:nth-child(2) {
  animation-delay: 0.1s;
}

.wave:nth-child(3) {
  animation-delay: 0.2s;
}

.wave:nth-child(4) {
  animation-delay: 0.3s;
}

.wave:nth-child(5) {
  animation-delay: 0.4s;
}

.wave:nth-child(6) {
  animation-delay: 0.5s;
}

.wave:nth-child(7) {
  animation-delay: 0.6s;
}

.wave:nth-child(8) {
  animation-delay: 0.7s;
}

.wave:nth-child(9) {
  animation-delay: 0.8s;
}

.wave:nth-child(10) {
  animation-delay: 0.9s;
}

@keyframes wave {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}


.content-wrapper {
  height: 95vh;
  margin: auto;
  align-items: center;
  padding: 10px;
  overflow-y: scroll;
}

.list-filter {
  margin: 10px auto 20px auto;
  width: 98%;
  min-width: 300px;

}

.submissions-container {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.09);
  border-style: solid;
  border-color: transparent;
  border-radius: 4px;
  background-color: white;
  padding: 20px 30px 20px 30px;
  overflow-wrap: break-word;
  overflow-x: scroll;
}

/* .list-filter-control {
  border-radius: var(--border-radius);
} */

/* .display-wrapper {
  -moz-transition: width 1s ease-in-out, left 1.5s ease-in-out;
  -webkit-transition: width 1s ease-in-out, left 1.5s ease-in-out;
  -moz-transition: width 1s ease-in-out, left 1.5s ease-in-out;
  -o-transition: width 1s ease-in-out, left 1.5s ease-in-out;
  transition: width 1s ease-in-out, left 1.5s ease-in-out;
}  */

/* ------New navbar------ */
/* .navbar-wrapper {
  position: fixed;
  height: 5.5em;
  top: 0;
  width: 100%;
  box-shadow: var(--box-shadow-light);
  background-color: white;
  //padding-bottom: 20px;
  color: var(--darkest-blue);
  z-index: 1000;
} */

.nav-pages {
  @include m.spread-value(padding-top, 5rem, 15rem);
  overflow-y: hidden;
  padding-bottom: 5rem;
  margin-bottom: -8rem;
}

/* .menu-collapse {
  display: none;
} */

/* .navbar-title {
  font-family: var(--sans-serif);
  color: var(--darkest-blue);
  font-size: calc(28px + (2 * ((100vw - 320px) / 680)));
  padding-bottom: 5px;
  font-weight: 600;
  display: flex;
  white-space: nowrap;
  text-align: right;
  margin: auto auto .5em 1em;
} */

/* @media screen and (min-width: 1000px) {
  .menu-collapse {
    display: flex;
  }
}

@media screen and (max-width: 450px) {
  .navbar-title {
    display: none;
  }
} */

/* .nav-icon {
  display: block;
  margin-right: 20px;
  font-size: 2.5em;
  font-weight: 800;
}

@media screen and (min-width: 1000px) {
  .nav-icon {
    display: none;
  }
}

@media screen and (max-width: 867px) {
  .nav-icon {
    padding-right: 30px;
  }
}

.navbar-logo {
  margin: -10px;
  height: var(--logo-max);
  min-width: 192px;
  cursor: pointer;
}
 */
/* .navbar-short-logo {
  margin: 15px 0 15px 20px;
  height: 60px;
  min-width: 35px;
  cursor: pointer;
}


.nav-links {
  padding-top: 1em;
}

.nav {
  margin-left: 20px;
  margin-right: 20px;
  padding-top: 20px;
  font-family: var(--sans-serif);
  font-size: calc(16px + (3 * ((100vw - 320px) / 680)));
  color: var(--darkest-blue);
  text-decoration: none;
} */

/* @media screen and (max-width: 1200px) {
  .nav {
    margin: auto 7px auto 7px;

  }
}

.nav:hover {
  color: var(--light-blue);
  text-shadow: 0 0 1px #fff;
}

.nav-menu {
  cursor: pointer;
}

.collapse-nav {
  display: block;
}

#nav-auth-btn {
  display: block;
  margin: 10px;
} */

/* @media screen and (max-width: 1000px) {
  .collapse-nav {
    display: none;
  }

  #nav-auth-btn {
    display: none;
  }
} */

.button-smaller {
  padding: 7px 10px 7px 10px;
  border: none;
  border-radius: 20px;
  font-size: 14px;
  background: var(--darkest-blue);
  color: white;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  text-decoration: none;
  white-space: nowrap;
}

#center-plx-btn {
  width: 200px;
  margin-top: 20px;
  margin-left: calc(50% - 100px);
}

.splash-button {
  margin-right: 10px;
  padding: 7px 20px 7px 20px;
  border: none;
  border-radius: 20px;
  font-size: 20px;
  background: var(--dark-blue);
  color: white;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
}

.splash-button-margins {
  margin-bottom: 20px;
}

.splash-button:hover {
  box-shadow: var(--box-shadow);
  background-color: var(--violet);
  color: white;
  cursor: pointer;
  -webkit-transition: all 0.1s;
  transition: all 0.1s;
}

/* begin test splash css */
.splash-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  justify-content: center;
  overflow: hidden;
  position: relative;
  top: 5.5em;
  bottom: -12em;
  background-color: #fff;
}

#slide-a {
  background-color: #fff;

}

.splash-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.splash-sub-headline {
  font-size: 24pt;
  font-family: 'Outfit, sans-serif';
  font-weight: 600;
  color: var(--dark-blue);
  margin-bottom: 10vh;
}

.splash-text {
  font-size: 16pt;
  font-family: 'Outfit, sans-serif';
  font-weight: 400;
  width: 80%;
  margin-top: 10vh;
  background-color: var(--alt-color);
  border: 2px solid gray;
  border-radius: 3px;
  padding: 10px;
}
/*
#signup-splash-btn {
  position: relative;
  bottom: 25vh;
  @include m.spread-value(width, 175px, 200px);
  margin: 0 auto 0 auto;
}

#about-splash-btn {
  position: relative;
  bottom: calc(25vh - .3em);
  @include m.spread-value(width, 175px, 200px);
  margin: 0 auto 0 auto;
}

#pricing-splash-btn {
  position: relative;
  bottom: calc(25vh - .6em);
  @include m.spread-value(width, 175px, 200px);
  margin: 0 auto 0 auto;
}
*/
#btn-icon {
  margin-left: 8px;
}

.logo-wrapper {
  display: flex;
  align-self: center;
  margin: 10vh 10vw;
}

.scale-logo {
  @include m.spread-value(width, 360px, 600px);
}

.about-panel {
  background-color: var(--light-blue);
  height: 100vh;
  width: 500px;
  max-width: 100%;
  @include m.spread-value(font-size, 1.5em, 4em);
}

/* end test splash css */

.aus-container {
  width: fit-content;
  margin: 7vw auto;
  padding: 0 5vw 0 5vw;
  justify-content: center;
}

.parallax-btn {
  width: 200px;
  margin: 5px auto 5px;

}

.slide-controls-horizontal {
  display: flex;
  flex-direction: "row";
  position: absolute;
  bottom: 10%;
  right: 10%;
  left: 50%;
  justify-content: space-between;
}

@media screen and (max-width: 867px) {
  .slide-controls-horizontal {
    width: 90%;
    left: 5%;
    right: 5%;
    bottom: 5%;
    justify-content: space-between;
  }

  .logo-wrapper {
    margin: 5vh 5vw;
  }

}

#pause {
  left: 25%;
  align-self: center;
}

#pause-bottom {
  position: absolute;
  right: 20px;
  bottom: 10vh;
  font-size: 22px;
}

#next-icon-top {
  position: absolute;
  top: 15vh;
}

#next-icon-bottom {
  position: absolute;
  bottom: 15vh;
}


.next-icon {
  font-size: 1.65rem;
  color: #000;
}

.next-icon:hover {
  cursor: pointer;
}


.user-submissions-section {
  justify-content: center;
  max-width: 100vw;
  height: fit-content;
  padding: 20px;
}

.floating-filter {
  width: 33%;
  min-width: 320px;
  margin: auto;
  padding: 1vmin;
}

.user-submission-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  margin: auto;
}



.custom-url {
  width: 50%;
  margin-left: 12.5vmin;
}

.app-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
}

.crm-panel {
  display: flex;
  flex-direction: row;
}


.crm-grid {
  display: flex;
  flex-wrap: wrap-reverse;
  width: auto;
  height: 100%;
  overflow: hidden;
  overflow: -moz-hidden-unscrollable;
}

.faculty-grid {
  display: flex;
  width: auto;
  height: 100%;
  flex-direction: column;
  justify-content: center;
}

.crm-column {
  margin: 0 auto 0 auto;
  padding: 0 20px;
  max-width: 100%;
  height: fit-content;
  height: -moz-fit-content;
  width: 600px;
  overflow: scroll;

  justify-content: center;
}

.faculty-column {
  margin: 0 auto 0 auto;
  padding: 0 30px 0 30px;
  width: 75%;
  height: 100%;
  overflow: clip;
  justify-content: center;
}

@media screen and (max-width: 300px) {
  .crm-column {
    width: 95%;
  }
}

@media screen and (max-width: 600px) {
  .faculty-column {
    width: 100%;
    padding: 0;
  }
}




.faculty-card-list {
  height: 90%;
  overflow-y: auto;
  overflow-x: clip;
  // -ms-overflow-style: none;
}

//.faculty-card-list::-webkit-scrollbar {
//  display: none;
//}


@media screen and (max-width: 900px) {
  .crm-column {
    min-width: 100%;
  }
}


.crm-title {
  border: 3px solid white;
  border-radius: 3px;
  font-size: calc(22px + (6 * ((100vw - 320px) / 680)));
  font-weight: 600;
  text-align: center;
  display: flex;
  flex-direction: row;
  width: fit-content;
  padding: 20px;
  color: #fff;
}

.crm-journal {
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  padding: 10px 20px 10px 20px;
}

.crm-journal-header {
  margin: auto 0 auto 0;
  font-size: 18px;
}


.faculty-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: calc(14px + (6 * ((100vw - 320px) / 680)));
  font-weight: 500;
  width: fit-content;
  margin: auto;
}

.faculty-header:hover {
  cursor: pointer;
}

#faculty-main {
  margin: auto;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.faculty-search-box h1 {
  color: white;
  font-size: calc(28px + (2 * ((100vw - 320px) / 680)));
  font-weight: 500;
  text-align: center;
  margin: 0 auto 10px auto;
}

.range-row {
  width: fit-content;
  max-width: 100%;
  justify-content: center;
  margin: auto;
  background-color: var(--light-blue);
  border-radius: 20px;
}

@media screen and (max-width: 450px) {
  .range-row {
    flex-direction: column;
    background-color: transparent;
  }
}


#modal-header {
  font-size: calc(18px + (4 * ((100vw - 320px) / 680)));
  margin: 0 auto 10px auto;
}

.crm-journal-row {
  display: flex;
  flex-direction: row;
}

.faculty-row {
  width: 100%;
  justify-content: space-between;
}

.faculty-link {
  text-decoration: none;
  text-align: left;
  color: white;
  font-size: calc(14px + (4 * ((100vw - 320px) / 680)));
  text-anchor: start;
}



.faculty-link:hover {
  color: white;
  text-shadow: 0 0 1px #fff;
}

#faculty-link-icon {
  padding: 0 0 5px 5px;
  font-weight: 600;
  text-anchor: end;
  margin-right: 5%;
}

#faculty-input {
  font-size: 16px;
  width: 100%;
  margin: auto auto auto 10px;
  border-radius: 15px;
  border: none;
  padding: 5px 10px 5px 10px;
}


.faculty-button-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 10px auto;
  width: fit-content;
  justify-content: center;
}

.faculty-search-icon {
  padding-left: 7px;
}

#faculty-search-button {
  background-color: var(--violet);
  cursor: pointer;
  padding: 0 10px 0 10px;
  border-radius: 20px;
  width: 110px;
  text-align: center;
  color: #fff;
  margin: 10px;
}

#faculty-search-button:hover {
  background-color: var(--light-blue);
  box-shadow: var(--box-shadow-light);
}

.details-link {
  text-decoration: none;
  color: var(--dark-blue);
}

.details-link:hover {
  color: var(--green);
}

.crm-journal-sub {
  margin-right: 20px;
  text-transform: uppercase;
  font-weight: 600;
  color: var(--dark-berry);
}

.journal {
  /* Color Stuff is just for contrast right now */
  display: flex;
  flex-direction: column;
  background-color: white;
  color: black;
  margin: .5vmin;
  border: solid 2px magenta;
  border-radius: 5px;
}

.crm-alert {
  width: 450px;
  max-width: 100%;
  height: fit-content;
  max-height: 100%;
}

.crm-alert-title {
  width: 450px;
  max-width: 100%;
  height: fit-content;
  max-height: 100%;
}

/* ------Footer------ */

.footer-wrapper {
  display: flex;
  background-color: var(--darkest-blue);
  justify-content: center;
  position: relative;
  height: 4.5em;
  bottom: 0;
  width: 100%;
  box-shadow: var(--box-shadow);
  flex-direction: row;
  flex-wrap: wrap;
  z-index: 900;
}


.footer-bar {
  justify-content: space-between;
  width: 75%;
  max-width: 1200px;
}


.footer-links {
  align-self: center;
  vertical-align: middle;
}

.footer-redirect {
  background-color: transparent;
  color: white;
  font-size: calc(11px + (3 * ((100vw - 320px) / 680)));
  border: none;
  cursor: pointer;
  text-decoration: none;
}

.footer-redirect:hover {
  color: white;
  text-shadow: 0 0 1px white;
}


/*------Payment------*/
.subscription-card {
  height: 450px;
  margin: 5vh auto;
  padding: 40px 60px;
  justify-content: space-between;
  text-align: center;
  font-size: calc(18px + (6 * ((100vw - 320px) / 680)));
}

.payment-copy span {
  font-size: 24px;
  font-weight: 600;
  color: var(--dark-green);
}

.subscription-text {
  max-width: 1100px;
  font-size: 24px;
  line-height: 1.2;
  margin: auto;
}

.payment-copy {
  display: flex;
  flex-direction: column;
  margin-left: 40px;

  align-items: center;
}

.payment-copy p {
  font-weight: 300;
}

@media screen and (max-width: 600px) {

  .payment-copy p {
    font-size: 18px;
  }


  .display-name {
    margin: 40px auto 10px auto;
  }
}

.payment-options-container {
  margin: auto auto 20px auto;
  justify-content: center;
  width: 100%;
  max-width: 1300px;
  padding: 10px;
}

.payment-btn {
  display: flex;
  @include m.spread-value(width, 150px, 200px);
  margin: 10px auto 20px auto;
  padding: 7px 25px 10px 25px;
  position: relative;
  border: none;
  border-radius: 20px;
  background: var(--dark-blue);
  color: white;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  justify-content: center;
  white-space: nowrap;
  align-self: flex-end;
  font-size: 18px;
  overflow: hidden;
}

.payment-btn:hover {
  box-shadow: var(--box-shadow);
  background-color: var(--violet);
  color: white;
  cursor: pointer;
  /*   -webkit-transition: all 0.1s;
  transition: all 0.1s; */
}

.payment-card {
  display: flex;
  @include m.spread-value(width, 280px, 300px);
  //@include m.spread-value(height, 400px, 425px);
  height: 400px;
  @include m.spread-value(margin, 5px, 20px);
  @include m.spread-value(padding-bottom, 20px, 40px);
  padding-top: 20px;
  justify-content: space-between;
  flex-direction: column;
}

.inst-card {
  display: flex;
  @include m.spread-value(width, 280px, 300px);
  height: 250px;
  @include m.spread-value(margin, 5px, 20px);
  flex-direction: column;
}

.payment-card-inner {
  justify-items: center;
  margin-left: 10%;
  font-size: 14pt;
}

@media screen and (max-width: 600px) {
  .payment-card-inner {
    margin: 0 auto;
    width: 80%;
  }
}

.inst-card-inner {
  align-self: flex-start;
  margin: 0 auto 0 auto;
  width: 80%;
  font-size: 24px;
}

.inst-span {
  color: var(--dark-blue);
  font-weight: 800;
}

.payment-detail-card {
  width: 800px;
  max-width: 100%;
  margin: 10% auto 0 auto;
  padding: 30px;
}

.payment-card-row {
  display: inline-flex;
  flex-direction: row;
  position: relative;
  width: fit-content;
}

.payment-detail-info {
  margin: 30px auto 30px auto;
}

/* .payment-detail-info span {
  font-weight: 800;
} */

.payment-detail-title {
  @include m.spread-value(margin-top, 25px, 35px);
  margin-bottom: 40px;
  font-family: var(--sans-serif);
  @include m.spread-value(font-size, 32px, 40px);
  font-weight: 600;
  color: var(--darkest-blue);
  text-align: center;
}

.inst-detail-title {
  @include m.spread-value(margin-top, 25px, 35px);
  margin-bottom: 10px;
  font-family: var(--sans-serif);
  @include m.spread-value(font-size, 32px, 40px);
  font-weight: 600;
  color: var(--darkest-blue);
  text-align: center;
}

.payment-price {
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  font-weight: 600;
}

.payment-price span {
  padding-right: 10px;
}

.plan-amount {
  text-align: center;
  @include m.spread-value(font-size, 1.5em, 1.75em);
  margin-top: 0;
}

.plan-discount {
  text-align: center;
  margin: -10px auto 5px auto;
  @include m.spread-value(font-size, 1.25em, 1.5em);
}

.confirm-detail-container {
  @include m.spread-value(width, 550px, 800px);
  max-width: 100%;
  height: fit-content;
}

.detail-body {
  min-width: 320px;
  width: 88%;
  margin: auto;
  font-family: var(--sans-serif);
  @include m.spread-value(font-size, 20px, 46px);
  padding: 5% 0 5% 0;
}

.detail-item {
  justify-content: space-between;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.detail-item-title {
  font-weight: 500;
  padding-bottom: 10px;
  width: fit-content;
  text-align: right;
}

.detail-item-content {
  padding: 0 0 10px 20px;
  width: auto;
  color: #fff;
}

.checkout-form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  max-height: 600px;
  max-width: 100%;
  overflow-x: hidden;
  padding-bottom: 20px;
}


.card-sm-box {
  display: inline;
  justify-content: space-between;
  column-span: all;
}

.form-btn {
  border-radius: var(--border-radius);
  margin: auto;
  padding: 1.25vmin;
  background-color: var(--dark-blue);
  width: 200px;
  font-size: 20px;
  color: #fff;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  text-decoration: none;
  white-space: nowrap;
  text-align: center;
}

#confirm-detail-btn {
  background-color: var(--light-violet);
  font-weight: 600;
  color: var(--dark-violet);
}

#confirm-detail-btn:hover {
  background-color: var(--violet);
  color: var(--color);
}

#analyze-form-btn {
  border-radius: 20px;
  width: 150px;
  font-size: calc(16px + (2 * ((100vw - 320px) / 680)));
  text-align: center;
  margin-top: 15px;
  background-color: var(--darkest-blue);
}

.submit-btn {
  //width: 175px;
  width: fit-content;
  border-radius: var(--border-radius);
  margin: auto;
  padding: .5vmin 1vmin .5vmin 1vmin;
  background-color: var(--dark-blue);
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  white-space: nowrap;
  text-align: center;
}

.form-btn-sm {
  border-radius: var(--border-radius);
  margin: auto;
  padding: 7px 0px 3px 0px;
  width: 55px;
  height: 55px;
  background-color: var(--dark-blue);
  font-size: 22px;
  color: #fff;
  cursor: pointer;
}

.form-btn:hover,
.submit-btn:hover {
  background-color: var(--violet);
}

.form-btn-sm:hover {
  background-color: var(--violet);
}

#form-icon {
  margin: 5px;
  vertical-align: middle;
  font-size: 32px;
}

.crm-btn {
  border-radius: var(--border-radius);
  margin: 10px;
  padding: 5px;
  width: 50px;
  min-width: fit-content;
  font-size: 16px;
  color: var(--color);
  overflow: hidden;
  position: relative;
  cursor: pointer;
  text-decoration: none;
  white-space: nowrap;
  text-align: center;
  justify-content: center;
  font-size: calc(14px + (2 * ((100vw - 320px) / 680)));
}

.crm-search-btn {
  background-color: var(--violet);
  border-radius: 20px;
  font-size: 14px;
  margin: 10px auto;
  padding: 5px 15px 5px 15px;
  width: fit-content;
  color: var(--color);
  cursor: pointer;
  text-decoration: none;
  white-space: nowrap;
  text-align: center;
  justify-content: center;
}

.faculty-search-btn {
  background-color: var(--violet);
  border-radius: 20px;
  font-size: 18px;
  margin: auto;
  padding: 5px 15px 5px 15px;
  width: fit-content;
  color: var(--color);
  cursor: pointer;
  text-decoration: none;
  white-space: nowrap;
  text-align: center;
  justify-content: center;
}

.faculty-img {
  border-radius: 50%;
  height: 110px;
  width: 110px;
  aspect-ratio: 1/1;
  margin-right: 40px;
  background-color: var(--lightest-blue);
  display: block;
}

@media screen and (max-width: 600px) {
  .faculty-img {
    display: none;

  }
}

@media screen and (max-width: 800px) {
  .faculty-title {
    font-size: 18px;
    width: 90%;
  }
}

.crm-search-box {
  flex-direction: column;
  justify-content: center;
  margin: 0 auto 10px auto;
  width: 70%;
}

.faculty-search-box {
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
  width: fit-content;
}

@media screen and (max-width: 600px) {
  .faculty-search-box {
    max-width: 95%;
  }
}


.crm-btn:hover {
  box-shadow: var(--box-shadow);
}

.crm-search-btn:hover {
  box-shadow: var(--box-shadow);
}

#crm-sent {
  background-color: var(--dark-green);
}

#crm-assign {
  background-color: var(--violet);
}

#crm-assign:hover {
  background-color: var(--light-violet);
}

#crm-owner {
  background-color: var(--dark-violet);
}

#crm-owner:hover {
  background-color: var(--dark-blue);
}

#crm-notes {
  background-color: var(--berry)
}

#crm-notes:hover {
  background-color: var(--light-berry);
}

#crm-empty {
  background-color: #7ac132;
}

#crm-empty:hover {
  background-color: #91c061;
}

#crm-send {
  background-color: var(--yellow);
}

#crm-sent:hover {
  background-color: var(--light-green);
}

#crm-send:hover {
  background-color: var(--light-yellow);
}


.payment-modal-btn {
  border-radius: var(--border-radius);
  border-style: none;
  text-align: center;
  margin: auto;
  padding: 5px 20px;
  background-color: var(--lightest-blue);
  width: 50px;
  font-size: 18px;
  color: var(--darkest-blue);
}

#payment-modal-cancel {
  margin: auto;
  padding: 5px;
  text-align: center;
  background-color: var(--dark-berry);
  font-size: 18px;
  color: #fff;
  width: 40%;
}

#payment-modal-renew {
  margin: auto;
  padding: 5px;
  background-color: var(--lightest-blue);
  text-align: center;
  font-size: 18px;
  color: var(--darkest-blue);
  width: 40%;
}

.payment-modal-btn:hover {
  background-color: var(--violet);
}

#modal {
  width: 90vw;
  max-width: 968px;
  height: fit-content;
  max-height: 70vh;
  color: var(--darkest-blue);
  background: white;
  padding: 30px;
  font-family: var(--sans-serif);
  z-index: 1000;
  left: calc(50% - 35vw);
  display: flex;
  flex-direction: column;
  box-shadow: var(--box-shadow);
}

#modal-dismiss {
  margin-top: 10px;
  margin-right: 15px;
  float: right;
}

#modal-body {
  padding: 0px 20px 10px 20px;
  text-align: left;
  font-size: 16px;
  font-family: var(--sans-serif);
}

.modal-close-button {
  padding: 16px;
  background-color: var(--blue);
  color: white;
  font-size: 1em;
  border: none;
  margin-top: 16px;
  width: 90%;
  align-self: center;
  cursor: pointer;
  transition: background-color 0.1s linear;
}

.modal-close-button:hover,
.modal-close-button:focus {
  background-color: #e8daff;
}

.modal-header {
  font-weight: 800;
  font-size: 32px;
  text-align: center;
  margin: auto;
}

.modal-body {
  padding: 20px 30px 20px 30px;
  overflow-y: scroll;
  overflow-x: hidden;
}


/* ----- On------ */
.auth-box {
  align-items: center;
  background: var(--dark-blue);
  border-radius: var(--border-radius);
  width: 30vw;
  min-width: 300px;
  max-width: 360px;
  margin-top: 2vh auto;
  padding: 5vmin 6vmin;
  box-shadow: var(--box-shadow);
}

.auth-box-title {
  margin-top: -10px;
  padding-bottom: 20px;
  font-family: var(--sans-serif);
  font-size: 2rem;
  font-weight: 600;
  color: white;
}

.ms-signon-img {
  padding-left: 12px;
  padding-right: 12px;
}

/* ------Results Panel------ */
.combined-results {
  width: 100vw;
}

.data-container {
  width: 100%;
  flex-wrap: wrap;
}



.basic-card {
  background-color: white;
  border: transparent;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15), 0 6px 20px 0 rgba(0, 0, 0, 0.12);
}

.todo-table-wrapper {
  padding: 20px;
  height: "fit-content";
  min-width: 360px;
  max-width: 75vw;
}

.citations-data {
  background-color: white;
  border-style: solid;
  border-radius: 4px;
  padding: 10px;
  height: fit-content;
  height: moz-fit-content;
  width: 330px;
}

.paper-cite {
  font-family: 'Times New Roman', Times, serif;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  font-size: var(--cite-size);
  white-space: preserve nowrap;
}

.nilla {
  display: inline;
  margin-left: 4pt;
}

.gdrive-button {
  display: flex;
  flex-direction: row;
  background-color: white;
  min-height: 24px;
  min-width: 175px;
  margin-left: 20px;
  padding: 3px;
  align-items: center;
  border-radius: 3px;
}

.gdrive-button:hover {
  cursor: pointer;
}

.gdrive-text {
  font-size: 17px;
  font-weight: 600;
}

.dropbox-button {
  padding: 3px;
  background-color: white;
  min-height: 24px;
  min-width: 175px;
  border-radius: 3px;
}

.dropbox-button:hover {
  cursor: pointer;
}

.circle-graph {
  -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, .2));
  filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, .2));
}

.author-list-viewer {
  margin-top: 1vh;
  overflow-y: auto;
  height: 82.5%;
  width: 100%;
  font-size: 1.1rem;
  max-height: 65vh;
  color: var(--darkest-blue);
  background-color: var(--color);
  border-radius: var(--border-radius);
}


/*--------Twitter FAB--------*/

.fab-container {
  width: 75px;
  height: 75px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  margin: auto;
  cursor: pointer;
}

.fab-container:hover {
  height: 75px;
}

.fab-container .fab {
  position: absolute;
  vertical-align: middle;
  width: 100%;
  height: 100%;
  background: #1DA1F2;
  bottom: 0;
  border-radius: 50%;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1000;
  box-shadow: var(--box-shadow);
}

.fab {
  position: relative;
  width: 300px;
  margin: 20px auto;
  color: white;
  background: #1DA1F2;
  align-content: center;
}

.fab-collapsed {
  border-radius: 100;
}

.fab-container:hover .fab {
  -webkit-transform: translatey(5px);
  transform: translatey(5px);
  -webkit-transition: all 0.1s;
  transition: all 0.1s;
  cursor: pointer;
  -webkit-transition: all 0.1s;
  transition: all 0.1s;
}

.fab-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: white;
  font-size: 37.5px;
}

.twitter-wrapper {
  right: 1vw;
  bottom: 5vh;
  position: fixed;
  z-index: 10000;
}

.follow-toggle {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}

.speech-bubble {
  padding: 5px;
  color: black;
  width: 300px;
  height: 210px;
  background: #1DA1F2;
  border-radius: 10px;
}

.speech-bubble:after {
  -moz-transform: skewX(45deg);
  -ms-transform: skewX(45deg);
  -webkit-transform: skewX(45deg);
  transform: skewX(45deg);
  content: "";
  position: fixed;
  left: 95%;
  width: 0;
  border-width: 20px 20px 0;
  border-style: solid;
  border-color: #1DA1F2 transparent;
}

@media screen and (max-width: 900px) {
  .speech-bubble:after {
    left: 90%;
  }
}

/* -----End Twitter FAB------ */

/* ------Fade In------ */
.fade-in-image {
  animation: fadeIn 5s;
  animation-delay: 1500;
  -webkit-animation: fadeIn 5s;
  -moz-animation: fadeIn 5s;
  -o-animation: fadeIn 5s;
  -ms-animation: fadeIn 5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  font-size: 18pt;
  font-weight: 500;
  line-height: 1.25;
}

.paywall {
  flex-direction: column;
  text-align: center;
  padding: 10vh 0;
  width: 700px;
  max-width: 100%;
  margin: auto;
  background-color: #fff;
}

/* ---Test Text Animations -----*/
.title-container {
  /* text-align: left; */
  display: inline-flex;
  position: static;
  font-size: 2rem;
  font-weight: 600;
}

.splash-block-wrapper {
  position: relative;
  top: 0;
  display: flex;
}

.splash-title {
  text-align: left;
  margin-left: 40px;
  font-family: var(--sans-serif);
  font-size: 2.6rem;
  font-weight: 600;

  color: var(--darkest-blue);
}

.pricing-title {
  text-align: center;
  font-family: var(--sans-serif);
  font-size: 1.65rem;
  font-weight: 600;
  color: var(--darkest-blue);
}

.pricing-card {
  width: 300px;
  max-width: 100%;
}

.slide-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  background-color: var(--color);
  overflow: hidden;
}

.slide-contents {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.slide {
  height: calc(100vh - 10em);
  position: relative;
  justify-content: center;
  top: 0;
  bottom: 0;
}

.slide-gif {
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  z-index: 10000;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  padding-top: 100px;
  font-family: var(--sans-serif);
  font-weight: 600;
  font-size: calc(32px + (6 * ((100vw - 320px) / 680)));
  color: var(--darkest-blue);
  justify-content: center;
  text-align: center;
}

.slide-gif span {
  font-family: var(--serif);
  font-style: italic;
  color: #fff;
}


#slide-left {
  width: 950px;
  padding-top: 100px;
  background-color: var(--light-blue);
  font-family: var(--sans-serif);
  font-weight: 600;
  font-size: calc(32px + (6 * ((100vw - 320px) / 680)));
  color: var(--darkest-blue);
  justify-content: center;
  text-align: left;
}

#slide-left span {
  font-family: var(--serif);
  font-style: italic;
  color: #fff;
}

#slide-left h1 {
  font-size: calc(48px + (6 * ((100vw - 320px) / 680)));
  position: relative;
}

#slide-header-sm {
  font-family: var(--sans-serif);
  font-weight: 600;
  font-size: 28px;
  color: var(--light-blue);
  padding-bottom: 32px;
  line-height: 1.2;
  display: none;
}

#slide-header-sm span {
  font-family: var(--serif);
  font-style: italic;
  line-height: 1.2;
  color: var(--darkest-blue);
}


#slide-right {
  background-color: var(--color);
  font-family: var(--sans-serif);
  min-height: 100%;
  height: inherit;
  width: 100%;
  justify-content: center;
  font-size: 16pt;
  color: #000;
  text-align: left;
  line-height: 1.1;
}

#slide-right-nav {
  height: fit-content;
  margin: auto;
  font-size: calc(16px + (4 * ((100vw - 320px) / 680)));
  padding: 0 20px 0 20px;
}

.slide-right-header {
  text-align: center;
  font-size: 22pt;
}

.slide-body {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: fit-content;
  padding: 10% 10% 30% 10%;
  align-self: center;
  overflow-y: auto;
  line-height: 1.15;
}

.faq-slide {
  width: 55%;
  margin: 5% auto auto 37%;
}


@media screen and (max-width: 640px) {
  .slide {
    width: 100vw;
  }

  .faq-slide {
    width: 100%;
    margin: 5vh auto;
  }

  /* 
  .slide-body {
    margin: 2%;
  } */

  #slide-left {
    display: none;
  }

  #slide-right {
    margin: auto;
    min-width: 95%;
  }

  #slide-header-sm {
    display: block;
    margin: 2% 0;
  }

  #slide-right-nav {
    margin: -2rem auto;
  }

  .slide-right-header {
    text-align: center;
    margin-top: 2rem;
    font-size: 18pt;
  }
}

.slide-logo {
  display: flex;
  flex-direction: column;
  //height: 100vh;
  background-color: var(--color);
}

.slide-button-block {
  display: flex;
  flex-direction: column;
  height: fit-content;
  position: relative;
  bottom: 7rem;
}

.splash-block {
  width: 30%;
  background-color: var(--light-blue);
  padding: 4vw 4vh;
}

.splash-block-text {
  width: 70%;
  padding: 5%;
  background-color: white;
}


.vertical-title {
  display: none;
  padding-top: 30%;
  padding-bottom: 20px;
  text-align: center;
}

@media screen and (max-width: 867px) {
  .splash-block {
    display: none;
  }

  .splash-block-text {
    width: 100%;
    margin-left: 0;
  }

  .vertical-title {
    display: block;
  }

}

.splash-block-title {
  margin: auto;
  padding-top: 10vh;
  font-family: var(--serif);
  font-style: italic;
  font-size: 3rem;
  color: white;
  text-align: center;
}


.parallax-bg {
  display: 'flex';
  justify-content: 'center';
  align-items: 'center';
}

#pl-odd {
  background-color: var(--blue);
}

#pl-even {
  background-color: var(--light-blue);
}

#pl-text {
  background-color: white;
}

.pl-text-bg {
  color: "black";
  background-color: "white";
  align-content: flex-end;
  right: 0;
}

.splash-byline {
  text-align: left;
  font-family: var(--serif);
  color: white;
  font-size: 2.6rem;
  font-style: italic;
  font-weight: 600;
}

.splash-copy {
  text-align: left;
  font-family: var(--sans-serif);
  color: black;
  font-size: 36px;
  font-weight: 400;
}


.splash-copy-vertical {
  text-align: left;
  font-family: var(--sans-serif);
  color: black;
  font-size: 1.35rem;
  font-weight: 400;
}


.logo-button-wrapper {
  position: absolute;
  bottom: 15%;
}

.splash-logo {
  min-width: 400px;
  max-width: 800px;
  width: 50vw;
  enable-background: new 0 0 720 233.8;
  margin: auto auto auto -20px;
}

.splash-copy #highlight {
  color: var(--blue);
  font-weight: 600;
}

.splash-copy-vertical #highlight {
  color: var(--blue);
  font-weight: 600;
}

.slopeBegin {
  background-color: var(--darkest-blue);
  clip-path: polygon(20% 0, 70% 0, 50% 100%, 0% 100%);
}

.slopeEnd {
  clip-path: polygon(70% 0, 100% 0, 80% 100%, 50% 100%);
}

.slopeBegin,
.slopeEnd {
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.content {
  position: absolute;
  top: 50%;
  left: 35%;
  transform: translate(-50%, -50%);
  transition: transform 30s ease-in;
  height: 160px;
  overflow: hidden;

  font-size: 35px;
  line-height: 40px;


  &__container {
    font-weight: 600;
    overflow: hidden;
    height: 40px;
    padding: 0 40px;

    #first-section {
      margin-top: 50px;
    }


    /*     &:before {
      content: '[';
      left: 0;
    }

    &:after {
      content: ']';
      position: absolute;
      right: 0;
    }

    &:after, &:before {
      position: absolute;
      top: 0;
      
      color: #1a2f40;
      font-size: 42px;
      line-height: 40px;
      
      -webkit-animation-name: opacity;
      -webkit-animation-duration: 2s;
      -webkit-animation-iteration-count: infinite;
      animation-name: opacity;
      animation-duration: 2s;
      animation-iteration-count: infinite;
    } */

    &__text {
      display: inline;
      float: left;
      font-family: var(--sans-serif);
      color: var(--darkest-blue);
      /*       transform: translateX(100%);
      transition: transform 0.3s ease-in; */
    }

    &__list {
      margin-top: 0;
      margin-left: 175px;
      padding-left: 100px;
      text-align: left;
      list-style: none;
      font-family: var(--serif);
      font-weight: 800;
      font-style: italic;
      color: var(--blue);

      -webkit-animation-name: change;
      -webkit-animation-duration: 10s;
      -webkit-animation-iteration-count: infinite;
      animation-name: change;
      animation-duration: 10s;
      animation-iteration-count: infinite;

      &__item {
        line-height: 40px;
        margin: 0;
      }
    }
  }
}

@-webkit-keyframes opacity {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@-webkit-keyframes change {

  0%,
  12.66%,
  100% {
    transform: translate3d(0, 0, 0);
  }

  16.66%,
  29.32% {
    transform: translate3d(0, -25%, 0);
  }

  33.32%,
  45.98% {
    transform: translate3d(0, -50%, 0);
  }

  49.98%,
  62.64% {
    transform: translate3d(0, -75%, 0);
  }

  66.64%,
  79.3% {
    transform: translate3d(0, -50%, 0);
  }

  83.3%,
  95.96% {
    transform: translate3d(0, -25%, 0);
  }
}

@-o-keyframes opacity {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@-o-keyframes change {

  0%,
  12.66%,
  100% {
    transform: translate3d(0, 0, 0);
  }

  16.66%,
  29.32% {
    transform: translate3d(0, -25%, 0);
  }

  33.32%,
  45.98% {
    transform: translate3d(0, -50%, 0);
  }

  49.98%,
  62.64% {
    transform: translate3d(0, -75%, 0);
  }

  66.64%,
  79.3% {
    transform: translate3d(0, -50%, 0);
  }

  83.3%,
  95.96% {
    transform: translate3d(0, -25%, 0);
  }
}

@-moz-keyframes opacity {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@-moz-keyframes change {

  0%,
  12.66%,
  100% {
    transform: translate3d(0, 0, 0);
  }

  16.66%,
  29.32% {
    transform: translate3d(0, -25%, 0);
  }

  33.32%,
  45.98% {
    transform: translate3d(0, -50%, 0);
  }

  49.98%,
  62.64% {
    transform: translate3d(0, -75%, 0);
  }

  66.64%,
  79.3% {
    transform: translate3d(0, -50%, 0);
  }

  83.3%,
  95.96% {
    transform: translate3d(0, -25%, 0);
  }
}

@keyframes opacity {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@keyframes change {

  0%,
  12.66%,
  100% {
    transform: translate3d(0, 0, 0);
  }

  16.66%,
  29.32% {
    transform: translate3d(0, -25%, 0);
  }

  33.32%,
  45.98% {
    transform: translate3d(0, -50%, 0);
  }

  49.98%,
  62.64% {
    transform: translate3d(0, -75%, 0);
  }

  66.64%,
  79.3% {
    transform: translate3d(0, -50%, 0);
  }

  83.3%,
  95.96% {
    transform: translate3d(0, -25%, 0);
  }
}


/*------ Fade Div ------*/
.fade-in {
  -webkit-transition: background-color 2s ease-out;
  -moz-transition: background-color 2s ease-out;
  -o-transition: background-color 2s ease-out;
  transition: background-color 2s ease-out;
}

/*------New Results Page------*/


.data-title {
  background-color: var(--darkest-blue);
  height: 50px;
  font-size: 32px;
  color: white;
}

.topics-list {
  background-color: var(--darkest-blue);
  border-radius: 4px;
  margin: 10px;
  padding: 0.5vmin;
  overflow: auto;
  height: fit-content;
  max-height: 400px;
  width: 20vw;
  min-width: 275px;
}

.data-item {
  padding: 5px;
  background-color: white;
}

.graph {
  margin-top: 10px;
  margin-bottom: 10px;
}


.author-list-wrapper {
  display: flex;
  flex-direction: column;
  max-height: 400px;
  min-width: 250px;
  width: 20vw;
  max-width: 350px;
}

/* .author-list-viewer {
  margin-top: 1vh;
  overflow-y: auto;
  height: 300px;
  width: 100%;
  font-size: 1.1rem;
  color: var(--darkest-blue);
  background-color: var(--color);
  border-radius: var(--border-radius);
} */

/*
.author-list-wrapper {
  max-height: 350px;
  width: 100%;
  overflow-y: auto;
}

.author-list-viewer {
  font-size: 1.1rem;
  max-height: 350px;
  min-width: 250px;
} 
*/
.disable-scroll {
  overflow-y: hidden;
}

.word-stats {
  display: flex;
  flex-direction: column;
  width: fit-content;
  width: moz-fit-content;
  height: fit-content;
  height: moz-fit-content;
  color: var(--darkest-blue);
  background-color: var(--color);
  margin: 20px;
}

.svg-text {
  visibility: hidden;
  animation: 1s fadeIn;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0
  }

  100% {
    visibility: visible;
    opacity: 1;
  }
}

/*------END New Results Page------*/

.missing-items-panel {
  width: 50vw;
}

.wrapper-split {
  margin: auto;
  width: 100%;
  min-width: 100%;
  overflow-y: scroll;
}

.column-range-ends {
  color: dodgerblue;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.row-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.right-margin {
  margin-right: 5px;
}

.topic {
  font-size: 1.25em;
  font-weight: 600;
  background-color: white;
  color: var(--darkest-blue);
  padding: 10px;
  position: sticky;
  width: 100%;
  border-radius: 5px;
}

.subject-odd {
  background-color: var(--light-blue);
  font-size: 2.25em;
  color: white;
  border: 3px solid white;
  border-radius: var(--border-radius / 2);
}

.subject-even {
  background-color: var(--dark-blue);
  border: 3px solid white;
  font-size: 2.25em;
  color: white;
  border-radius: var(--border-radius / 2);
}

.reg-button {
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 10vw;
}

.topics {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}


.right-spaced {
  margin-left: 1vw;
}

.create-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.create-button {
  margin-top: 3vh;
}

.journal-subs {
  margin-top: 9vh;
}

.column-range-ends:hover {
  cursor: pointer;
}

.nav-bar-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-right: 1vmin;
}

.nav-level {
  align-items: center;
  justify-content: center;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.submit-paper-row-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 300px;
}

.submit-paper-panel {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.submit-paper-button-group {
  display: flex;
  flex-direction: row;
}

.submitted-item {
  width: 10vw;
}

.submitted-item-ops {
  padding: 2vmax;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
}

.submitted-item-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.submitted-item-title-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.submitted-item-title {
  font-size: 1rem;
  width: 20vw;
  min-width: 20vw;
  max-width: 20vw;
  overflow-wrap: break-word;
  word-break: break-all;
}

.action-buttons {
  display: flex;
  flex-direction: column;
}

.action-button {
  width: 75%;
  border-radius: 3px;
  margin: 1px;
}

.range-column {
  margin-bottom: 0.5vmin;
  background-color: var(--color);
}

.nav-item-color {
  padding: 1vmin;
  color: var(--color);
  background-color: var(--darkest-blue);
}

.nav-item-color:hover {
  cursor: pointer;
  background-color: var(--darkest-blue);
  border: solid 1vmin var(--color);
}

.submit-button {
  padding: 1vmin;
  max-width: 10vw;
}

.hoverer {
  cursor: pointer;
  color: dodgerblue;
  padding-left: 5vw;
}

.rgw {
  width: 5vw;
}

.rowgroup {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  padding-top: 2vmin;
}

.fc {
  min-width: 50px;
  max-width: 50px;
}

.align-right {
  margin-top: 0.25vh;
  margin-right: 0.25vw;
  text-align: right;
}

.flex-column {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.flex-row {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.flex-row-between {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.filter-column {
  width: 250;
  margin-bottom: 0.5vmin;
  max-height: 400px;
  overflow: auto;
}

.topic-filter-column {
  width: 100%;
  margin-bottom: 0.5vmin;
  overflow: auto;
}

/* @media (max-width: 867px) {
  .filter-column {
    width: 100vw;
    margin-left: -20;
    margin-right: -20;
  }
} */

.filter-row {
  width: 100%;
  background-color: var(--light-blue);
  color: white;
  border-radius: 3px;
  border: 1px solid var(--alt-color);
  font-size: 1.25em;
  font-weight: 400;
}

.notes-title {
  font: "Outline";
  font-style: italic;
  font-size: 1.5em;
  font-weight: 400;
}

.column-close {
  font-size: 0.75em;
}

.notes-text {
  min-height: 70vh;
  height: 70vh;
}

.editor-class {
  min-height: 25vh;
  max-height: 70vh;
  border: 1px solid gray;
  overflow: auto;
}

.msg-editor-class {
  min-height: 30vh;
  max-height: 30vh;
  border: 1px solid gray;
  overflow: auto;
}


.notes-buttons {
  display: flex;
  flex-direction: row-reverse;
  align-items: baseline;
  justify-content: space-between;
}

.filter-buttons {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
}

.split-panel {
  display: flex;
  flex-direction: row;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 5px;
  height: auto;
  border-style: solid;
  border-radius: 4px;
  border-color: white;
  border-width: 10px;
  margin-top: 0px;
  margin-bottom: 30px;
  background-color: white;
  max-width: 100%;
}

.rangeable-column {
  width: 90%;
  margin: auto;
  padding-top: 10px;
}

.column-range-view {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.column-range-title {
  font-weight: 600
}

.column-range-title:hover {
  cursor: pointer;
}

.spaced-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.5vmin;
  margin-bottom: 1vmin;
}

.right-row {
  text-align: right;
}

.column-range-entry {
  font-size: 14px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  min-width: 50px;
  max-width: 100px;
}

.hidden-icon {
  visibility: hidden;
}

.floating-icon {
  font-size: 14pt;
  position: absolute;
  color: var(--dark-blue);
  font-weight: 400;
  z-index: 99999999;
}

.floating-icon:hover {
  color: var(--light-blue);
  font-weight: 600;
  cursor: pointer;
}

.filter-panel {
  width: 35%;
  max-width: 300px;
  margin: 10px;
  min-height: 50vh;
  height: fit-content;
  background-color: white;
}

@media screen and (max-width: 867px) {
  .filter-panel {
    width: 100%
  }
}

.results-filters {
  width: 100%;
}

.file-list-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: var(--color);
  background-color: var(--darkest-blue);
}

.file-list-close {
  text-align: right;
  padding-right: 1vmin;
  padding-top: 0.25vmin;
  padding-bottom: 0.25vmin;
}

.file-list-close-icon:hover {
  cursor: pointer;
}

.filter-button-panel {
  display: flex;
  flex-direction: row;
  margin-top: 3vh;
}

.text-filter-panel-list {
  height: 70%;
  overflow-y: auto;
}

.submitted-item-icon {
  margin-right: 1vw;
}

.submission-card {
  max-width: 80vw;
  box-shadow: var(--box-shadow);
  margin: 5vh auto 5vh auto;
  background: var(--alt-color);
}

.submission-header-margin {
  margin-bottom: 10px
}

.submission-table {
  max-width: 75vw;
  margin: auto;
  overflow-x: scroll;
  background: var(--alt-color);
  overflow-y: hidden;
}

.submission-portal {
  display: flex;
  flex-direction: row;
}

.submission-filters {
  width: 20vw;
}

.submission-fill {
  overflow: auto;
}

.active-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.five-over {
  margin: auto;
}

.results-viewer-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 60vw;
  height: 60vh;
  margin: 1vh auto;
}

.center-margin {
  margin: "auto"
}

.maf-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.maf-input-panel {
  display: flex;
  flex-direction: column;
  margin-top: 1vmin;
  width: 10vw;
}

.maf-button {
  color: var(--color);
  background-color: var(--darkest-blue);
  padding: 1vmin;
  margin-top: 1vmax;
  margin-bottom: 1vmax;
  align-items: center;
  justify-content: center;
}

.maf-button:hover {
  cursor: pointer;
}

.mlogout {
  display: flex;
  flex-direction: row-reverse;
  margin-left: auto;
  margin-right: 1vw;
  margin-bottom: 1vw;
}


.profile-spacer {
  margin-bottom: 10px;
}

.merge-button {
  padding-left: 1vmin;
}

.merge {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.merge-item {
  padding: 0.25vmin;
}

.merge-target {
  background-color: var(--darkest-blue);
}

.author-item {
  display: flex;
  flex-direction: row;
  align-items: top;
  justify-content: space-between;
}

.author-item-field {
  margin: 1vmin;
  width: 50%;
}

.license-item {
  padding: 0.5vmin;
  margin-top: 1vmin;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--color);
}

.island-top {
  margin-top: 1vh;
}

.submit-paper-last-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.license-title {
  padding: 0.5vmin;
  color: var(--color);
  background-color: var(--darkest-blue);
  width: 20vw;
}

.license-text {
  margin-top: 1vmin;
  text-align: justify;
  width: 40vw;
}

.group-field {
  width: 20vw;
}

.group-options {
  width: 20vw;
}

.group-item {
  width: 50%;
  margin: 1vmin;
}

.group-items {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.group-selector-button {
  margin: 1vmin;
}

.group-selector-panel {
  margin: 1vmin;
}

.spacer {
  margin: 2.5vh;
}

.payment-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: top;
}

.subscription-container {
  display: flex;
  height: 100%;
  padding-top: 5vh;
  border-radius: var(--border-radius);
  justify-content: center;
  align-items: center;
  vertical-align: middle;
}

.subscription-content {
  max-width: 1201px;
  margin: auto;
}

.subscription-panel {
  width: 20vw;
  min-height: 20vh;
  margin: 1vmin;
}

.checkout-title {
  margin: auto;
  width: 100%;
  color: var(--light-violet);
  font-family: var(--sans-serif);
  font-size: calc(36px + (6 * ((100vw - 320px) / 680)));
  text-align: center;
  font-weight: 600;
}

.checkout-footer {
  margin-top: 20px;
  padding-top: 20px;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: center;
  vertical-align: bottom;
  border-top: solid rgb(214, 229, 245, .5);
  width: 100%;
}

#cancel {
  background: var(--dark-berry);
  font-weight: 600;
}

#cancel:hover {
  background: var(--berry);
}

@media screen and (max-width: 437px) {
  #cancel {
    margin-top: 5px;
  }

  .checkout-footer {
    padding-top: 25px;
    margin-top: 45px;
  }
}

.sub-instructions {
  max-width: 50%;
  margin: auto;
  padding-top: 20px;
  text-align: center;
}

.subscription-exp-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100%;
}

.subscription-exp {
  font-weight: 600;
}

.results-viewer-controls {
  display: flex;
  direction: row;
  justify-content: space-between;
}

.results-single-item {
  flex: 1;
  padding: 2px;
}

.results-page-item {
  width: 50%;
  overflow-y: auto;
}

.buttonGroup {
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.page-item-container {
  padding: 3px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  overflow: auto;
}

.bitl {
  font-weight: bold;
  font-style: italic;
  word-wrap: inherit;
}

.itl {
  font-style: italic;
  word-wrap: inherit;
}

.bld {
  font-weight: bold;
  word-wrap: inherit;
}

.pl {
  word-wrap: inherit;
}

.payment {
  display: flex;
  flex-direction: column;
  margin-top: 1vmin;
}

.payment-row {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

@media screen and (max-width: 960px) {
  .payment-row {
    flex-wrap: wrap;
  }
}

@media screen and (min-width: 1000px) {
  .payment-row {
    flex-wrap: nowrap;
  }
}


.payment-options {
  margin-top: 1vmin;
  background-color: var(--color);
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.payment-option {
  background-color: var(--alt-color);
  border: 1px var(--darkest-blue) solid;
  width: 15vw;
  padding: 1vmin;
}

.payment-name {
  margin: 1vmax;
  text-align: center;
  font-size: 1vmax;
  font-weight: bold;
  border-radius: var(--border-radius);
  color: var(--darkest-blue);
}

.payment-notes {
  margin-top: 1vmin;
  color: var(--color);
  background-color: var(--darkest-blue);
}

.payment-name-active {
  margin: 1vmax;
  text-align: center;
  font-size: 1vmax;
  font-weight: bold;
  border-radius: var(--border-radius);
  background-color: var(--color);
  color: black;
}

.recent-modal {
  width: 75vw;
  height: 75vh;
  padding: 1vmin;
}

.payment-option-active {
  color: var(--color);
  background-color: var(--darkest-blue);
  border: 4px var(--darkest-blue) solid;
  width: 15vw;
  padding: 1vmin;
}

.modal-close {
  margin-left: 10 vw;
  color: white;
}

.modal-close:hover {
  cursor: pointer;
  color: black;
}

.payment-notes-active {
  margin-top: 1vmin;
  color: black;
  background-color: var(--color);
}

.alias-parts {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: var(--color);
  margin-top: 1vmin;
}

.auto-expanding-list {
  display: flex;
  flex-direction: column;
  width: 20vw;
}

.auto-expanding-list-button-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.auto-expanding-list-button {
  color: var(--color);
  background-color: var(--darkest-blue);
  border-radius: var(--border-radius);
  margin-top: 1vh;
  padding-top: 1vh;
  padding-bottom: 1vh;
  width: 50%;
}

.auto-expanding-list-button:hover {
  text-decoration: underline;
  cursor: pointer;
}

.auto-expanding-list-title {
  background-color: var(--darkest-blue);
  color: var(--color);
  margin-top: 2vh;
}

.missing-item-icon {
  color: darkred;
  font-size: var(--icon-size);
}

.remove-item-icon {
  color: black;
  font-size: var(--icon-size);
}

.auto-expanding-list-item {
  margin-top: 1vh;
}

.available-user-controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 1vh;
}

.available-user-submit {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: var(--color);
  background-color: var(--darkest-blue);
  padding: 1vmin;
  width: 10vw;
  border-radius: var(--border-radius);
}

.available-user-submit:hover {
  color: var(--hover-color);
  background-color: var(--darkest-blue);
  cursor: pointer;
  text-decoration: underline;
}

.spaced-list {
  display: block;
  overflow-y: auto;
}

.author-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 20vw;
  min-width: 200px;
  max-height: 50vh;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
}

.author-card-header {
  padding-top: 0.5vh;
  height: 16vh;
  width: 100%;
  background-color: var(--darkest-blue);
  color: #fff;
}

.author-card-body {
  padding: 0.5vh;
  height: 24vh;
  max-height: 50vh;
  overflow-y: scroll;
}

.author-search-item {
  padding: 0.5vmin;
}

.expanded-author {
  overflow: auto;
}

/*
.expanded-author-list {
  overflow: auto;
}
*/
.display-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-left: 1vw;
  margin-right: 1vw;
  margin-top: 3vh;
  width: 18vw;
  height: 72vh;
}

.display-column-title {
  color: var(--color);
  background-color: var(--darkest-blue);
  width: 10vw;
  padding: 1vmin;
}


.results-panel {
  padding-top: 10vh;
  width: 90vw;
  overflow-x: hidden;
  text-align: center;
  margin: auto;
}

.results-wrapper {
  padding-top: 1vh;
  width: 90vw;
  max-width: 2000px;
  overflow-x: hidden;
}

.results-list {
  overflow-y: auto;
}

.data-viz-wrapper {
  flex-direction: "column"
}

.data-viz-panel {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 20;
  margin: auto;
  justify-content: center;
  align-items: center;
  width: 95vw;
}

@media screen and (min-width: 1921px) {
  .display-panel {
    height: 95vh;
  }



  .tile-title-mode {
    height: 175px;
    width: 175px;
    line-height: 175px;
  }

  .tile-title-mode-active {
    height: 175px;
    width: 175px;
    line-height: 175px;
  }

}

.display-left {
  width: 20vw;
}

.display-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 15vw;
}

.display-panel-loggedin {
  display: flex;
  flex-direction: column;
  align-content: center;
  width: 100%;
  height: 65vh;
  padding-left: 1vmin;
  padding-right: 1vmin;
}

.display-columns {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* .display-name {
  margin: 1vmax;
  text-align: center;
  font-size: 20px;
  border-radius: var(--border-radius);
  background-color: var(--darkest-blue);
  color: var(--color);
}

.display-text {
  background: var(--color);
  padding: 1vmax .5vmax 1vmax .5vmax;
  margin: 2vmax;
  text-align: left;
} */

.display-list {
  list-style: circle;
  list-style-type: circle;
}

.paper-hide-msg {
  color: var(--color);
  background-color: var(--darkest-blue);
  font-size: 1.1rem;
  font-weight: 400;
  padding-top: 1vmin;
  padding-bottom: 1vmin;
}

/*
.paper-viz {
   height: 40vh;
  width: 60vw; 
}
*/

.viz-item {
  width: 100%;
  height: 50%;
}

.viz-item:hover {
  cursor: pointer;
}

.viz-item:hover {
  background-color: var(--darkest-blue);
}

.paper-network {
  height: 50vh;
  width: 40vw;
}

.paper-stream {
  height: 50vh;
  width: 40vw;
}

.popper {
  display: inline-block;
  background-color: white;
  position: absolute;
  padding: 1vmax;
  z-index: 99;
}

.square {
  width: 200px;
  height: 225px;
  margin: auto;
}

.big-square {
  width: 55vw;
  height: 55vh;
}

/* .topics-list {
  background-color: var(--darkest-blue);
  padding: 0.5vmin;
  overflow-y: auto;
  overflow: scroll;
  height: fit-content;
  min-height: 295px;
  width: 20vw;
} */

.subtopics {
  padding-top: 1vh;
  font-size: .9rem;
  height: 85%;
  overflow-y: scroll;
}



/* .citations-data {
  margin-top: 1vmin;
  margin-right: 1vmin;
  padding: 0.5vmin;
  background-color: var(--darkest-blue);
  overflow-y: scroll;
  height: fit-content;
} */

/* .author-list-wrapper {
  width: 15vw;
  height: 330px;
  border-style: solid;
  border-radius: 4px;
}



.word-stats {
  display: flex;
  flex-direction: column;
  width: 25vh;
  color: var(--darkest-blue);
  background-color: var(--color);
  padding-top: 1vmin;
  padding-right: 1vmin;
  padding-left: 1vmin;
} */

.wsva {
  min-height: 12.5vh;
  width: 95%;
  padding: 1.5vh 1vw 1.5vh 1vw;
  border-bottom: 1vmin solid var(--darkest-blue);
}

.wsvah {
  font-size: 36pt;
  font-weight: 600;
}

.wsvas {
  margin-top: -1vh;
  font-size: 16pt;
  font-weight: 400;
  padding-bottom: 1vh;
}

.wsvb {
  min-height: 12.5vh;
  font-size: 36pt;
  font-weight: 600;
}

.dash-item {
  margin-top: 1.25vh;
  padding: 1vmin;
  background-color: white;
  width: 25vmin;
}

.dash-text {
  padding-bottom: 0.5vmin;
}

.dash-nav {
  padding-left: 0.5vmin;
  padding-right: 0.5vmin;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.dash-data {
  height: 15vh;
  width: 15vh;
  margin-left: 5vh;
}

.title-split {
  border-right: 0.25vmin solid var(--alt-color);
}

.dash-title {
  font-size: 24px;
  color: var(--color);
  background-color: var(--darkest-blue);

  border-radius: var(--border-radius);
}

.dash-major-title {
  text-decoration: underline;
  border-radius: var(--border-radius);
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 1vmin;
  color: var(--color);
  background-color: var(--darkest-blue);
  width: 100%;

}

.analyze-wrapper {
  display: flex;
  padding: 1vmin;
  direction: row;
  align-items: center;
  justify-content: center;
}

.split-results {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
  height: 50vh;
}

.split-item {
  min-width: 50%;
  width: 50%;
}

.split-title {
  margin: 1vmin;
  color: var(--color);
  background-color: var(--darkest-blue);
}

.sad-results {
  color: var(--darkest-blue);
  background-color: var(--color);
  margin-top: 5vh;
  font-size: 14t;
  text-align: justify;
}

.bigger {
  padding: 1vmin;
  min-height: 6vh;
}

.bigger-text {
  font-size: 14pt;
  font-weight: 600;
}

.full-results {
  width: 100%;
}

.download-results {
  padding: 1vmin;
  margin-top: 1vmin;
  margin-bottom: 2vmin;
  text-align: center;
  width: 23vh;
}

.combined-results {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 5vh;
  font-size: 1.1rem;
  width: 100vw;
}

.combined-row-odd {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  color: var(--darkest-blue);
  background-color: var(--color);
  width: 100%;

}

.filled-list {
  width: 100%;
  padding: 10px;
  margin: 'auto';
  display: block;
}

@media screen and (max-width: 867px) {
  .filled-list {
    width: 100vw;
  }
}

.combined-row-even {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  color: var(--color);
  background-color: var(--dark-blue);
  width: 100%;

}

.combined-list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1vmin;
  width: 100%;
  height: 100%;
  font-size: 15px;
}

.combined-item-data {
  text-align: left;
  width: 100%;
}

.centered-div {
  margin-left: auto;
  margin-right: auto;
}

.dashboard-maintenance {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.maintenance-update-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 28vw;
  margin: 1vmin;
}

.spaced-label {
  margin-right: 2vmin;
}

.right-toggle {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-end;
}

.mup-start {
  color: var(--color);
  background-color: var(--darkest-blue);
  padding: 1.5vmin;
}

.mup-start:hover {
  cursor: pointer;
  background-color: var(--darkest-blue);
}

.mup-results {
  padding: 1vmin;
  overflow: auto;
}

.loading-message {
  background-color: var(--color);
  height: 25vh;
  width: 50vw;
  margin-top: 3vh;
}

.manage-user-add {
  margin-top: 5vh;
}

.manage-user-add-item {
  margin: 3px;
}

.manage-user-add-label {
  background-color: var(--darkest-blue);
  color: var(--color);
}

.manage-user-add-input {
  height: 7vh;
  width: 100%;
}

.managed-user-available {
  color: var(--color);
  background-color: var(--darkest-blue);
}

.msg-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fspace {
  margin-right: auto;
  margin-left: auto;
  width: 80%;
}

.header-space {
  margin-top: 3vh;
}

.user-list {
  height: 30vh;
  width: 100%;
  overflow: auto;
}

.profile-edit-toggle {
  display: flex;
  justify-content: flex-end;
}

.read-only-panel {
  pointer-events: none;
}

.active-user-profile {
  margin-top: 1vh;
  padding: 1vmin;
  background-color: var(--color);
  border-radius: 3px;
}

.active-user-selector {
  font-size: var(--cite-size);
}

.active-user-selector-filter {
  display: flex;
  flex-direction: column;
}

.active-user-selector-filter-label {
  color: var(--color);
  background-color: var(--darkest-blue);
}

.active-user-title {
  font-family: var(--sans-serif);
  font-size: 22px;
  font-weight: 600;
  color: var(--dark-blue);
}

.modal-status {
  width: 25vw;
  height: 25vh;
  padding: 1vmin;
}

.modal-button {
  margin-top: 1vh;
}

/* .active-user-column {
  display: inline-flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: fit-content;
  width: fit-content;
  align-self: center;
  margin: 0 20px;
  justify-content: center;
} */

.active-user-icon {
  margin-right: 10px;
  vertical-align: middle;
}

.active-user-email {
  font-weight: 400;
  font-size: 18px;
  color: var(--blue);
}

.active-user-form-btn {
  margin-top: 20px;
  padding: 5px 10px 5px 10px;
}

.active-user-selector-table {
  display: flex;
  flex-direction: column;
  width: 900px;
  max-width: 100%;
  background-color: var(--color);
  padding: 20px;
  border-radius: 20px;
  height: fit-content;
  max-height: 100%;
  box-shadow: var(--box-shadow-light);
  margin: auto;
}

@media screen and (max-width: 1050px) {

  .active-user-selector-table {
    margin-bottom: 10px;
  }
}

/* 
#top-card {
  margin-top: 25%;
} */

.crm-title {
  font-size: 28px;
  font-weight: 600;
  margin: auto;
  padding-right: 10px;
  text-align: center;
  border-style: none;
}

.crm-box {
  background-color: var(--darkest-blue);
  margin: auto;
  padding: 30px;
  height: 100vh;
  width: 100%;
  border-radius: 5px;
  font-family: var(--sans-serif);
  justify-content: space-between;
  overflow: clip;
}

.faculty-box {
  background-color: var(--darkest-blue);
  margin: auto;
  padding-top: 20px;
  width: 100%;
  font-family: var(--sans-serif);
  justify-content: center;
  overflow: clip;
}

.faculty-title {
  flex-wrap: wrap;
  width: fit-content;
  max-width: 550px;
  font-weight: 400;
  font-size: 20px;
}

.faculty-title:hover {
  color: white;
  text-shadow: 0 0 1px #fff;
}



.crm-card {
  display: flex;
  flex-direction: column;
  background-color: var(--dark-blue);
  margin: 5px auto 5px auto;
  height: fit-content;
  width: 500px;
  max-width: 75vw;
  box-shadow: var(--box-shadow);
  border-radius: 5px;
  justify-content: center;
  color: var(--color);
}

.crm-card:hover {
  background-color: var(--blue);
  cursor: pointer;
}

.faculty-card {
  padding: 10px;
  width: 100%;
}


.crm-icons {
  font-size: 20px;
  margin: 0 7px 0 7px;
  font-weight: 400;
  cursor: pointer;
}

.crm-journal-icons {
  font-weight: 600;
}

.crm-journal-icons:hover {
  font-weight: 800;
}

#crm-close-icon {
  color: var(--light-green);
  font-size: 14px;
}

#crm-close-icon:hover {
  color: var(--berry);
}

.crm-card-list {
  height: 75vh;
  overflow-y: auto;
  overflow-x: clip;
}

.crm-card-content {
  background-color: var(--color);
  font-size: 18px;
  text-align: left;
  font-family: var(--sans-serif);
  color: #000;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 100%;
  min-height: fit-content;
  word-wrap: break-word;
  padding: 10px;
  cursor: pointer;
}

.crm-content-detail {
  display: flex;
  flex-direction: row;
  margin: 5px;
  justify-content: space-between;
}

.crm-inner-title {
  //background-color: var(--light-violet);
  padding: 20px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  height: 100%;
  font-weight: 600;
}

.active-user-profile-card {
  display: flex;
  background-color: var(--color);
  padding: 20px;
  width: 360px;
  margin: 0 auto 10px auto;
  height: fit-content;
  box-shadow: var(--box-shadow-light);
  border-radius: 20px;
  justify-content: center;
}

.active-user-selector-filter-input {
  padding: 1vmin;
}

.active-user-selector-filter-w-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1vh;
}

.active-user-selector-refresh {
  color: var(--color);
  background-color: var(--darkest-blue);
  border: 1px solid var(--color);
  border-radius: var(--border-radius);
  padding: 1vmin;
}

.active-user-selector-refresh:hover {
  cursor: pointer;
  text-decoration: underline;
}

.active-user-selector {
  padding: 1vmin;
  background-color: var(--color);
  border-radius: var(--border-radius);
}

.active-user-selector-items {
  margin-top: 1vh;
  border-radius: var(--border-radius);
  overflow-y: auto;
  max-height: 25vh;
}

.active-user-selector-icon {
  margin-left: 1vw;
}

.active-user-selector-item-odd {
  display: flex;
  align-items: flex-start;
  padding: 1vmin;
  color: var(--color);
  background-color: var(--darkest-blue);
  border-radius: var(--border-radius);
  font-size: 1rem;
}

.active-user-selector-item-even {
  display: flex;
  font-size: 1rem;
  align-items: flex-start;
  padding: 1vmin;
  color: var(--darkest-blue);
  background-color: var(--color);
  border-radius: var(--border-radius);
}

.active-user-selector-item-odd-active {
  display: flex;
  color: var(--color);
  justify-content: space-between;
  padding: 1vmin;
  background-color: var(--darkest-blue);
  border: 1px solid var(--darkest-blue);
  border-radius: var(--border-radius);
}

.active-user-selector-item-even-active {
  display: flex;
  color: var(--darkest-blue);
  background-color: var(--color);
  justify-content: space-between;
  padding: 1vmin;
  border: 1px solid var(--darkest-blue);
  border-radius: var(--border-radius);
}

.active-user-selector-item-odd:hover {
  cursor: pointer;
  text-decoration: underline;
}

.active-user-selector-item-even:hover {
  cursor: pointer;
  text-decoration: underline;
}

.active-user-selector-item-deactivate-odd {
  border: 1px solid var(--color);
  border-radius: var(--border-radius);
  padding-left: 1vmin;
  padding-right: 1vmin;
  padding-top: 1px;
  padding-bottom: 1px;
}

.active-user-selector-item-deactivate-even {
  border: 1px solid var(--darkest-blue);
  border-radius: var(--border-radius);
  padding-left: 1vmin;
  padding-right: 1vmin;
  padding-top: 1px;
  padding-bottom: 1px;
}

.active-user-selector-item-deactivate-odd:hover {
  color: var(--color);
  background-color: var(--darkest-blue);
  text-decoration: underline;
  cursor: pointer;
}

.active-user-selector-item-deactivate-even:hover {
  color: var(--color);
  background-color: var(--darkest-blue);
  text-decoration: underline;
  cursor: pointer;
}

.user-account-settings-options {
  background-color: var(--color);
  margin-top: 1vh;
}

.user-account-settings-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 1vh;
  margin-left: 1vw;
  margin-right: 1vw;
}

.aff {
  margin-top: 1vh;
  background-color: var(--color);
}

.user-account-settings-item-label {
  color: var(--color);
  background-color: var(--darkest-blue);
  border: 1px solid var(--color);
  border-radius: var(--border-radius);
  padding: 3px;
}

.user-account-settings-item-value {
  color: var(--darkest-blue);
  background-color: var(--color);
  padding: 3px;
  border-radius: var(--border-radius);
}

.profile-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
  padding: 40px;
}

@media screen and (max-width: 1000px) {
  .profile-column {
    width: 100%;
  }
}

/* .profile-form-column1 {
  flex-direction: column;
  width: 30%;
} */

.profile-form-column2 {
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin: 30px 40px;
  flex-wrap: wrap;
}

.profile-part {
  background: var(--color);
  padding: 1vmax;
  margin: 1vmax;
  width: 50vw;
  height: 30vh;
  text-align: center;
}

.profile-element {
  width: 100%;
  height: 100%;
}

.profile-button {
  background-color: var(--blue);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
  padding: 20px 10px 20px 10px;
  border-radius: var(--border-radius);
  border: 2px solid transparent;
  font-size: 18pt;
  font-weight: 500;
  font-family: var(--sans-serif);
  color: white;
  right: 0;
}

.profile-button:hover {
  background-color: white;
  border: 2px solid var(--blue);
  color: var(--blue);
}

#btn-bottom {
  margin-top: 40px;
  margin: auto;
}

.profile-add {
  text-align: right;
  margin-right: 1vw;
}

.manage-user-available-row-odd {
  color: var(--darkest-blue);
  background-color: var(--color);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--color);
  border-radius: var(--border-radius);
}

.manage-user-available-row-even {
  color: var(--color);
  background-color: var(--darkest-blue);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--color);
  border-radius: var(--border-radius);
}

.manage-user-available-row-odd-active {
  color: var(--darkest-blue);
  background-color: var(--color);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--darkest-blue);
  border-radius: var(--border-radius);
}

.manage-user-available-row-even-active {
  color: var(--color);
  background-color: var(--darkest-blue);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: var(--border-radius);
  border: 1px solid var(--darkest-blue);
}

.manage-user-available-name-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 1vmin;
}

.manage-user-available-name {
  padding: 1vmin;
}

.manage-user-available-email {
  padding: 1vmin;
}

.signed-on {
  background: var(--color);
}

.nocheck {
  pointer-events: none;
}

.nav-wrapper {
  height: fit-content;
}

.fixed-view-port {
  position: relative;
  margin: auto;
  background: var(--alt-color);
}

.basic-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 5.5em);
  width: 100%;
  background-color: var(--alt-color);
  justify-content: center;
  overflow: auto;
  position: relative;
  padding-top: 30px;
  bottom: 0;
  top: 5.5em;
  overflow: auto;
}

.signon-wrapper {
  padding: 25px;
  margin: 0 auto 0 auto;
}

.signon-panel {
  background: var(--darkest-blue);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  color: var(--color);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  padding: 3vmin;
  width: 360px;
  max-width: "100%";
  position: relative;
  height: fit-content;
}


.signon-holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.login-box {
  display: flex;
  flex-direction: column;
  margin-top: 2vh;
  align-items: justify;
  justify-content: center;
}

.login-create {
  color: var(---color);
  margin-bottom: 1vh;
}

.login-create:hover {
  text-decoration: underline;
  cursor: pointer;
}

.login-item {
  margin-top: 1vh;
}

.login-msg {
  margin-bottom: 1vh;
  color: var(--color);
}

.login-error {
  color: yellow;
}



.signon-msg {
  font-family: var(--sans-serif);
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 1vh;
  margin-top: 2vh;
  color: var(--light-violet);
}

.signon-button {
  font-family: var(--sans-serif);
  font-weight: 600;
  font-size: 20px;
  background-color: var(--blue);
  color: var(--color);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
  padding: 5px 10px 5px 10px;
  border-radius: 20px;
  border: 1px solid transparent;
  width: 250px;
  margin-top: 2vh;
  margin-right: auto;
  margin-left: auto;
  white-space: nowrap;
}

.signon-button:hover {
  cursor: pointer;
  background-color: var(--color);
  color: var(--blue);
  border: 1px solid var(--blue);
}

.form-body {
  margin: auto;
  width: 100%;
  height: 450px;
  max-height: 100%;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: auto;
}

.form-box {
  height: 100%;
  width: 225px;
  margin: auto;
  padding: 20px 10px 0 10px;
}

.input-column {
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 5px;
}

.basic-input-wrapper {
  display: inline-flex;
  flex-wrap: nowrap;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
}

.basic-input {
  border-radius: 15px;
  border: none;
  font-size: 14px;
  padding: 5px 10px 5px 15px;
  margin: 5px 10px 5px 10px;
  min-width: 200px;
  width: 63%;
  background-color: var(--color);
}

.sm-input {
  border-radius: 15px;
  border: none;
  font-size: 14px;
  padding: 5px 10px 5px 15px;
  margin: 5px 10px 5px 10px;
  width: 100px;
  background-color: var(--color);
}

.basic-input-blue {
  border-radius: 15px;
  border: none;
  font-size: 16px;
  padding: 5px 10px 5px 15px;
  width: 300px;
  background-color: var(--alt-color);
  cursor: pointer;
}

#crm-input {
  min-width: 300px;
  max-height: 30px;
}

.basic-textarea {
  border-radius: 15px;
  border: none;
  font-size: 14px;
  padding: 5px 10px 5px 15px;
  margin: 5px 10px 5px 10px;
  min-width: 200px;
  width: 63%;
  background-color: #f6f2f2;
}

.expedite-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.expedite-from {
  padding: 1vmin;
  margin: 1vmin;
}

.ok-cancel-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 50%;
  padding: 1vmin;
  margin: 1vmin;
}

.selection-master-check-wrapper {
  color: white;
  background-color: var(--darkblue);
  margin: 1vmin;
}

.profile-input {
  border-radius: 15px;
  border: none;
  font-size: 16px;
  padding: 5px 10px 5px 15px;
  margin: 5px 10px 5px 10px;
  max-width: 100%;
  background-color: #f6f2f2;
}

.modal-input {
  border-radius: 15px;
  border: none;
  font-size: 16px;
  padding: 5px 5px 5px 10px;
  width: 100%;
  margin: auto;
  background-color: #f6f2f2;
  color: black;
}

/* @media screen and (max-width: 500px) {
  .modal-input {
    width: 400px;
    max-width: 100%;
  }
} */

.basic-input:focus {
  outline-color: var(--violet);
  box-shadow: inset 0 0 10px var(--light-violet);
}

.basic-input-label {
  font-weight: 600;
  font-size: 16px;
  padding-top: 10px;
  width: fit-content;
  color: var(--darkest-blue);
}

.modal-input-label {
  font-weight: 600;
  font-size: 14px;
  padding-left: 10px;
  width: 110%;
  color: var(--darkest-blue);
}

#modal-input-label-dark {
  color: var(--light-violet);
}

.combobox-icon {
  padding: 0 10px 0 5px;
  /*   vertical-align: bottom; */
  color: var(--darkest-blue);
}

#input-required {
  color: var(--berry);
}

.ci-right {
  float: right;
}

.ci-right:hover {
  font-weight: 600;
  cursor: pointer;
}

.combobox-input {
  border: none;
  background: transparent;
  padding-left: 5px;
}

.combobox-input:focus {
  outline-style: none;
}

.dropdown-wrapper {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  position: absolute;
  border: none;
  font-size: 16px;
  margin: 0px 10px 0px 20px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 2px 4px 10px 0 rgba(59, 64, 66, 0.3);
  z-index: 1200;
}

.dropdown-item {
  width: 100%;
  font-size: 14px;
}

.dropdown-item:hover {
  background-color: #f6f2f2;
  cursor: pointer;

}

.search-input {
  border-radius: 15px;
  border: var(--blue);
  font-size: 16px;
  padding: 5px 10px 5px 15px;
  margin: 5px 10px 5px 10px;
  width: 100%;
  background-color: #fff;
}

#combobox-label {
  text-align: right;
  padding-top: 7px;
  padding-right: 15px;
  width: fit-content;
}

.combo-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: auto auto 20px auto;
  justify-content: space-between;
  width: 100%;
}

@media screen and (max-width: 500px) {
  .combo-container {
    flex-wrap: wrap;
  }
}

.profile-category-block {
  font-size: 16px;
  font-weight: 600;
  color: var(--darkest-blue);
  width: 100%;
  margin: 5px auto;
}

.sf-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: auto;
  margin-top: 20px;
  width: 850px;
}

.signon-input {
  border-radius: 15px;
  border: none;
  font-size: 16px;
  padding: 5px 10px 5px 15px;
  margin: 10px;
  width: 250px;
  background-color: #fff;
}

.signon-input:focus {
  outline-color: var(--blue);
}

.signon-item {
  text-align: center;
  align-items: center;
  justify-items: center;
  width: 100%;
}

.email-reset {
  font: "Outline";
  font-size: 1.5em;
  font-weight: 600;
  background-color: var(--blue);
  padding: 0.5vmin;
  border-radius: 3px;
  margin-bottom: 1vh;
}

.msft-signon-item {
  display: flex;
  font-family: 'Arial', sans-serif;
  font-size: 13.5px;
  font-weight: 600;
  color: #5E5E5E;
  padding: 12px;
  max-width: 200px;
  min-width: 200px;
  border: 1px #8C8C8C;
  margin: 5px auto;
  max-height: 40px;
  background-color: var(--color);
  justify-items: flex-start;
  border-radius: 3px;
}

.msft-signon-item:hover {
  cursor: pointer;
  background-color: var(--alt-color);
}

.signon-button:hover {
  color: var(--darkest-blue);
  background-color: var(--alt-color);
}

.interests {
  margin-bottom: 2vh;
}

.labeled-checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;

  justify-content: center;
  padding: 1vh;
}

.lc-label {
  color: var(--color);
  background-color: var(--darkest-blue);
  width: 10vw;
}

.lc-field {
  background-color: var(--darkest-blue);
  margin-left: 10px;
}

.labeled-field {
  padding: 1vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.lf-label {
  color: var(--color);
  background-color: var(--darkest-blue);
  width: 15vw;
}

.lf-field {
  width: 30vw;
  margin-left: 5px;
}

.command-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 15vw;
}

.command-item {
  background-color: var(--darkest-blue);
  color: var(--color);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
  padding: 10px;
  border-radius: var(--border-radius);
  border: 1px solid transparent;
  font-size: 14pt;
  font-weight: 500;
  font-family: Roboto, sans-serif;
  width: 12.5vw;
  margin-bottom: 0.2vmin;
  text-decoration: none;
}

.activate-button {
  margin-top: 1vh;
}

.filter-item {
  margin: .5vh auto 1vh auto;
}

.command-item-pending {
  background-color: var(--alt-color);
  color: var(--color);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
  padding: 10px;
  border-radius: var(--border-radius);
  border: 1px solid transparent;
  font-size: 14pt;
  font-weight: 500;
  font-family: Roboto, sans-serif;
  width: 12.5vw;
  margin-bottom: 0.2vmin;
  text-decoration: none;
}


.default-colors {
  color: var(--color);
  background-color: var(--darkest-blue);
}

.command-item-active {
  background-color: var(--darkest-blue);
  color: var(--color);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
  padding: 10px;
  border-radius: var(--border-radius);
  border: 1px solid transparent;
  font-size: 14pt;
  font-weight: 500;
  font-family: Roboto, sans-serif;
  width: 12.5vw;
  margin-bottom: 0.2vmin;
  text-decoration: underline;
}

.command-item-active:hover {
  color: var(--hover-color);
  text-decoration: underline;
}

.command-item:hover {
  color: var(--hover-color);
  background-color: var(--darkest-blue);
  text-decoration: underline;
}

.command-sub-item {
  background-color: var(--alt-color);
  color: var(--hover-color);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
  padding: 10px;
  border-radius: var(--border-radius);
  border: 1px solid transparent;
  font-size: 14pt;
  font-weight: 500;
  font-family: Roboto, sans-serif;
  width: 12.5vw;
  margin: 2px;
  text-decoration: none;
}

.command-sub-item:hover {
  background-color: var(--color);
  border: 1px solid var(--hover-color);
  text-decoration: underline;
}

.command-active-sub-item {
  background-color: var(--color);
  color: var(--hover-color);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
  padding: 10px;
  border-radius: var(--border-radius);
  border: 1px solid var(--hover-color);
  font-size: 14pt;
  font-weight: 500;
  font-family: Roboto, sans-serif;
  width: 12.5vw;
  margin: 2px;
  text-decoration: underline;
}

.submission-portal-controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 35vw;
  height: 50px;
}

.portal-editors {
  text-align: center;
  margin: auto;
}

.portal-column-select {
  width: 10vw;
  margin-bottom: 1vmin;
  font-size: 1.25em;
  padding: 1vmin;
  min-height: 50px;
  border-radius: 5px;
  box-shadow: var(--box-shadow);
}

.sortable:hover {
  color: white;
  background-color: dodgerblue;
  cursor: pointer;
}

.command-active-sub-item:hover {
  border: 1px solid var(--hover-color);
  text-decoration: underline;
}

.author {
  color: var(--color);
  background-color: var(--darkest-blue);
  text-align: center;
  height: 5vh;
  padding-top: 1.5vh;
  font-size: 12pt;
}

.author-count {
  font-size: 40pt;
  text-align: center;
}

.author-details {
  text-align: center;
  height: fit-content;
}

.author-cell {
  min-height: 30px;
  box-sizing: border-box;
  padding: 5px 0 5px 0;
  border: 3px solid white;
  border-radius: var(--border-radius / 2);
}

.author-even {
  color: var(--color);
  background-color: var(--dark-blue);
}

.author-odd {
  font-weight: 500;
  color: var(--color);
  background-color: var(--light-blue);
}

.author-search {
  font-weight: 500;
  padding: 1vmin;
  overflow: auto;
}

.author-paper-wrapper {
  display: flex;
  flex-direction: row;
}

.author-paper-even {
  display: flex;
  flex-direction: row;
  color: var(--color);
  background-color: var(--dark-blue);
}

.author-paper-odd {
  display: flex;
  flex-direction: row;
  color: var(--darkest-blue);
  background-color: var(--color);
}

.author-paper-title-odd {
  color: dodgerblue;
  text-decoration: underline;
}

.author-paper-title-even {
  color: white;
  text-decoration: underline;
}

.onedrive-button {
  background-color: white;
  height: auto;
  min-width: 175px;
  margin: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3px;
  border-radius: 3px;
}

.onedrive-button:hover {
  cursor: pointer;
}

.onedrive-icon {
  max-height: 32px;
  margin-right: 20px;
}

.onedrive-text {
  font-size: 17px;
  font-weight: 600;
  color: #3b3a39;
}

.relevant-icon {
  margin-right: 1vw;
}

.bluebook-type-selector {
  color: var(--color);
  background-color: var(--darkest-blue);
  border-radius: var(--border-radius);
}

.bluebook-status {
  margin-right: 2vw;
}

.bluebook-status-report {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bluebook-status-report-odd {
  color: var(--color);
  background-color: var(--darkest-blue);
  border: 1px solid var(--hover-color);
  padding: 1vmin;
}

.bluebook-status-report-even {
  color: var(--darkest-blue);
  background-color: var(--color);
  border: 1px solid var(--hover-color);
  padding: 1vmin;
}

.bluebook-citation {
  background: var(--color);
  border: 1px solid var(--color);
  border-radius: var(--border-radius);
}

.bb-short {
  font-size: larger;
  font-weight: bold;
}

.bb-cite {
  display: inline;
}

.bb-cite:hover {
  cursor: pointer;
}

.bb-book-authors {
  font-variant: small-caps;
}

.bb-book-title {
  font-variant: small-caps;
}

.bb-journal-title {
  font-style: italic;
}

.bb-journal-journal {
  font-variant: small-caps;
}

/*

const cKeys = {
  "book" : ["volume", "authors", "title", "page", "editors", "edition", "publisher", "year"],
  "case" : ["shortcite"],
  "journal" : ["author", "title", "vol", "volume", "journal", "page", "year", "comments"]
}

.bb-journal {

}
*/

.bluebook-content-editable {
  border: 1px solid var(--darkest-blue);
  border-radius: var(--border-radius);
  padding-top: 1vh;
  padding-left: 1vw;
  padding-right: 1vw;
  background-color: ghostvar(--color);
}

.bluebook-content-editable-pane {
  background-color: var(--color);
  padding: 2vmin;
  border-radius: var(--border-radius);
}

.nilla {
  display: inline;
}

.smol {
  display: inline;
  font-variant: small-caps;
  margin-left: 4pt;
}

.ital {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  font-style: italic;
  margin-left: 4pt;
}

.bluebook-type-selector {
  margin-left: 1vw;
  padding: 1vmin;
}

.bluebook-content-editable-controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color);
  border: 1px solid var(--alt-color);
  border-radius: var(--border-radius);
  padding-left: 1vw;
  padding-right: 1vw;
  padding-top: 3px;
  padding-bottom: 3px;
}

.citation-scroller {
  overflow: auto;
  max-height: 10vh;
}

.citation-item-odd {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: var(--color);
  background-color: var(--darkest-blue);
  border-radius: var(--border-radius);
  border: 1px solid var(--color);
  padding: 1vmin;
}

.citation-item-title-odd {
  color: var(--color);
}

.citation-item-title-even {
  color: var(--darkest-blue);
}

.citation-item-even {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: var(--darkest-blue);
  background-color: var(--color);
  border-radius: var(--border-radius);
  border: 1px solid var(--color);
  padding: 1vmin;
}

.bb-row-enter {
  opacity: 0;
}

.bb-row-enter-active {
  opacity: 1;
  transition: opacity 400ms;
}

.bb-row-exit {
  opacity: 1;
}

.bb-row-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

.wm-res-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.citation-item-prog {
  background-color: var(--color);
  border: 1px solid var(--hover-color);
  width: 5vw;
  min-width: 5vw;
}

.pay-wall {
  color: var(--color);
  background-color: var(--darkest-blue);
  border: 1px solid var(--color);
  border-radius: var(--border-radius);
  margin-top: 3vh;
}

.invest-panel {
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: flex-start;
  justify-content: center;
  margin-top: 5vh;
  margin-bottom: 2vh;
}

.invest-cb {
  margin-top: 2vh;
  font-weight: bold;
}

.citation-item-table-prog {
  background-color: var(--color);
  border: 1px solid var(--hover-color);
}

.bb-content-editable-button-panel {
  display: flex;
  flex-direction: row;
}

.bb-edit-button {
  color: var(--hover-color);
  background-color: var(--alt-color);
  border: 1px solid var(--color);
  border-radius: var(--border-radius);
  padding: 1vmin;
}

.bb-edit-button-active {
  border: 1px solid var(--color);
  border-radius: var(--border-radius);
  color: var(--color);
  background-color: var(--darkest-blue);
  padding: 1vmin;
}

.bb-citation-link {
  color: var(--color);
  background-color: var(--darkest-blue);
  padding: 1px;
  padding-left: 1vw;
  padding-right: 1vw;
  border: 1px solid var(--color);
  border-radius: var(--border-radius);
}

.bb-citation-title-wrapper {
  display: flex;
  direction: row;
}

.bb-citation-title {
  display: flex;
  flex-direction: row;
  color: var(--color);
  background-color: var(--darkest-blue);
  font-weight: bold;
  padding-top: 1vh;
  padding-bottom: 1vh;
  padding-left: 1vw;
  padding-right: 1vw;
  justify-content: space-between;
}

.bb-citation-title-value {
  margin-left: 2vw;
  margin-right: 0vw;
  border: 1px solid var(--color);
  border-radius: var(--border-radius);
  padding-left: 1vw;
  padding-right: 1vw;
}

.bluebook-row {
  border: 1px solid var(--alt-color);
  border-radius: var(--border-radius);
  margin-bottom: 1px;
}

.bluebook-controls {
  background-color: var(--color);
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
}

.bluebook-data {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border: 1px solid var(--color);
  border-radius: var(--border-radius);
  align-content: space-between;
  justify-content: space-between;
}

.google-sign-in {
  padding-bottom: 5px;
  margin: auto;
  max-width: 200px;
  min-width: 200px;
}

.App {
  background-color: #f2f2f2;
  text-align: center;
}

.twitter-panel {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 15vw;
}

.twitter-cb {
  text-align: right;
}

.bb-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bb-missing {
  color: goldenrod;
}

.bb-missing-reverse {
  color: goldenrod;
}

.bb-download {
  color: var(--darkest-blue);
}

.bb-download-reverse {
  color: var(--color);
}

.bluebook-field {
  background-color: var(--alt-color);
  border: 1px solid var(--color);
  border-radius: var(--border-radius);
}

.bluebook-field-title {
  color: var(--color);
  background-color: var(--darkest-blue);
  border: 1px solid var(--color);
  border-radius: var(--border-radius);
}

.bluebook-field-data {
  color: var(--hover-color);
  height: 20pt;
  margin: 1vh 1vw;
  background-color: var(--color);
  border-radius: var(--border-radius);
  border: 1px solid var(--color);
}

.bluebook-item-accept-reject {
  display: flex;
  flex-direction: row;
}

.info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: var(--border-radius);
  padding: 0.5vmin;
  background-color: lightblue;
  margin-bottom: 1vh;
}

.warning {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: var(--border-radius);
  padding: 1vmax;
  background-color: lightgoldenrodyellow;
}

.error {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: var(--border-radius);
  padding: 1vmax;
  background-color: lightcoral;
}

.editabletable-header {
  display: flex;
  flex-direction: row;
  color: var(--color);
  background-color: var(--darkest-blue);
  justify-content: space-between;
  border-radius: var(--border-radius);
  border: 1px solid var(--color);
  padding: 1vmin;
}

.editabletable-header-toggle-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-right: 1vw;
}

.editabletable-header-toggle.react-toggle-track {
  background-color: var(--alt-color);
}

.editabletable-header-toggle.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: var(--color);
}

.editabletable-header-toggle.react-toggle--checked .react-toggle-track {
  background-color: var(--darkest-blue);
}

.editabletable-header-toggle.react-toggle-thumb {
  border: 1px solid #4d4d4d;
  background-color: #fafafa;
}

.editabletable-header-toggle.react-toggle--checked .react-toggle-thumb {
  border-color: var(--darkest-blue);
}

.editabletable-header-toggle-label {
  margin-left: 1vw;
}

.editabletable-wrapper-orig {
  position: fixed;
  left: 20vw;
}

.editabletable-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  margin-top: 2vh;
  padding: 1vmin;
  width: 100%;
  overflow: auto;
}

.editabletable-page-selector {
  border-radius: var(--border-radius);
  margin-left: 5px;
}

.editabletable-pager {
  display: flex;
  flex-flow: row;
  color: var(--color);
  align-items: center;
  justify-content: center;
  background-color: var(--darkest-blue);
}

.editabletable-pager-jump {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.editabletable-pager-input {
  border: 1px solid var(--color);
  border-radius: var(--border-radius);
}

.editabletable-pager-input-text {
  text-align: right;
}

.editabletable-pager-input-text {
  margin: 0vh 1vw;
}

.editabletable-page-input-text {
  margin-left: 1vw;
  margin-right: 1vw;
}

.paper-upload-button-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1.5vmin;
}

.prompt-major {
  color: var(--color);
  background-color: var(--darkest-blue);
  padding: 1vmin;
  font-size: 1.5rem;
  font-weight: 700;
}

.paper-holder {
  background-color: var(--alt-color);
}

.prompt-minor {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 1vmin;
  margin-top: 1vmin;
  background-color: white;
}

.prompt-sub {
  margin: 1vmin;
}

.clickable-div {
  color: var(--color);
  background-color: var(--darkest-blue);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px,
    rgba(85, 15, 15, 0.24) 0px 0px 1px 0px;
  padding: 10px;
  border-radius: var(--border-radius);
  border: 1px solid transparent;
  font-size: 14pt;
  font-weight: 500;
  font-family: Roboto, sans-serif;
  margin: 2px;
}

.clickable-div:hover {
  color: var(--darkest-blue);
  cursor: pointer;
  text-decoration: underline;
}

.bottom-link {
  margin-right: 3vw;
  color: var(--darkest-blue);
  background-color: var(--color);
}

.editabletable-pager-button {
  color: var(--color);
  background-color: var(--darkest-blue);
  width: 3vw;
  border: 1px solid var(--color);
  border-radius: var(--border-radius);
  margin-left: 1vw;
}

.editabletable-viewer-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--color);
  background-color: var(--darkest-blue);
  padding: 1vmin;
  border-radius: var(--border-radius);
}

.editabletable-viewer-button:hover {
  background-color: var(--darkest-blue);
  cursor: pointer;
}

.editabletable-viewer-button-active {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--color);
  background-color: var(--darkest-blue);
  padding: 1vmin;
  border-radius: var(--border-radius);
}

.editabletable-viewer-button-button {
  display: flex;
  flex-direction: row;
  margin-right: 1vmin;
}

.editabletable-pager-button:hover {
  background-color: var(--darkest-blue);
}

.editabletable-pager-button:disabled {
  color: var(--hover-color);
  background-color: var(--alt-color);
}

.editabletable-panel-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.editabletable-panel-commands {
  display: flex;
  flex-direction: row;
  align-content: space-between;
  justify-content: space-between;
  margin-top: 2vh;
  width: 15vw;
}

.search-download-icon {
  color: white;
}

.submission-portal-column-item {
  margin-top: 1vh;
}

.portal-options {
  text-align: right;
}

.immutable-paper-odd {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: var(--color);
  background-color: var(--darkest-blue);
}

.immutable-paper-even {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: var(--darkest-blue);
  background-color: var(--color);
}

.immutable-paper-icon {
  padding: 1vmin;
}

.cv-file-wrapper {
  color: var(--color);
  font-size: 18px;
  font-family: var(--sans-serif);
  background-color: var(--dark-blue);
  border-radius: var(--border-radius);
  cursor: pointer;
  padding-top: 0.5vmax;
  padding-bottom: 0.5vmax;
  padding-right: 1.5vmax;
  padding-left: 1.5vmax;
}

.input-file-wrapper {
  color: var(--color);
  font-size: 22px;
  font-family: var(--sans-serif);
  background-color: var(--dark-blue);
  border-radius: var(--border-radius);
  cursor: pointer;
  padding-top: 1vmax;
  padding-bottom: 1vmax;
  padding-right: 1.75vmax;
  padding-left: 1.75vmax;
}

.input-file-wrapper-disabled {
  color: black;
  background-color: var(--alt-color);
  border-radius: var(--border-radius);
  cursor: pointer;
  padding-top: 1vmax;
  padding-bottom: 1vmax;
  padding-right: 1.75vmax;
  padding-left: 1.75vmax;
}

.display-body {
  background-color: var(--color);
  display: flex;
  direction: row;
  flex-wrap: wrap;
}

.usage-day {
  padding: 2vmax;
  border: 1px solid var(--darkest-blue);
}

.usage-month {
  padding: 2vmax;
  color: var(--color);
  background-color: var(--darkest-blue);
}

.usage-year {
  padding: 2vmax;
  color: var(--color);
  background-color: var(--darkest-blue);
}

.usage-label {
  height: 100px;
}

.usage-time-wrapper {
  background-color: var(--color);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1vmax;
}

.usage-time-label {
  color: var(--color);
  background-color: var(--darkest-blue);
  padding: 1vmin;
}

.usage-time-selector {
  color: var(--darkest-blue);
  background-color: var(--color);
  width: 10vw;
}

.corner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 18vh;
  width: 18vw;
  border-radius: var(--border-radius);
  font-weight: bold;
}

.bg-wrapper {
  color: var(--color);
  background-color: var(--darkest-blue);
  width: 100%;
  height: 100%;
}

.bg {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bg-item {
  color: var(--hover-color);
  background-color: var(--alt-color);
  border-radius: var(--border-radius);
  margin: 5px;
  padding: 7px 15px;
  font-size: 16pt;
  font-weight: 400;
  text-decoration: none;
}

.bg-item-active {
  color: var(--hover-color);
  background-color: var(--color);
  border-radius: var(--border-radius);
  margin: 5px;
  padding: 7px 15px;
  font-size: 16pt;
  font-weight: 400;
  text-decoration: none;
}

.bg-item:hover {
  color: var(--hover-color);
  background-color: var(--color);
  border-radius: var(--border-radius);
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
  padding: 7px 15px;
  text-decoration: underline;
}

.bg-button {
  border: none;
  box-shadow: none;
  text-decoration: none;
}

.bg-button:hover {
  text-decoration: underline;
}

.nav-bg-msg {
  font-size: 1.5em;
  font-weight: bold;
  color: var(--color);
  padding: 2vh;
}

/* .main {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75vw;
  border-radius: var(--border-radius);
  background-color: var(--darkest-blue);
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
} */

.input-file-wrapper:hover {
  background-color: var(--violet);
  text-decoration: none;
  box-shadow: var(--box-shadow);
}

.input-file {
  display: none;
}

.node-search-top {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-between;
}

.node-search-panel {
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 1800px;
  margin: auto;
  justify-content: center;
}

.node-search-row {
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  justify-content: flex-start;
  margin-bottom: 1vh;
}

.node-pager-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 1vmin;
}

.node-page-selector {
  color: var(--darkest-blue);
  background-color: var(--color);
  border-radius: var(--border-radius);
  border: 1px solid var(--color);
  width: 12vw;
}

.search-button-disabled {
  pointer-events: none;
}

.node-search-input {
  border-radius: var(--border-radius);
  border: 1px solid var(--color);
  width: 12vw;
}

.node-search-bottom {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.node-search-sub {
  display: flex;
  flex-direction: row;
  margin-right: 1vw;
  width: 50%;
}

.node-search-label {
  color: var(--color);
  background-color: var(--darkest-blue);
  margin-left: 0vw;
  margin-right: 2vw;
  padding: 1vmin;
  border-radius: var(--border-radius);
  width: 12vw;
}

.padded {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1vh;
  margin-bottom: 1vh;
}

.active-user-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: var(--darkest-blue);
  background-color: var(--color);
  box-shadow: rgba(94, 38, 38, 0.24) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
  padding: 10px;
  border-radius: var(--border-radius);
  border: 1px solid transparent;
  font-size: 14pt;
  font-weight: 500;
  font-family: Roboto, sans-serif;
  margin: 2px;
}

.active-user-item-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.active-user-item-name {
  margin-right: 3vw;
}

.profile-papers-item {
  background-color: var(--darkest-blue);
  color: var(--color);
  box-shadow: rgba(94, 38, 38, 0.24) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
  padding: 10px;
  border-radius: var(--border-radius);
  border: 1px solid transparent;
  font-size: 14pt;
  font-weight: 500;
  font-family: Roboto, sans-serif;
  margin: 2px;
}

.profile-papers-item-active {
  background-color: var(--darkest-blue);
  color: var(--color);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
  padding: 10px;
  border-radius: var(--border-radius);
  border: 1px solid transparent;
  font-size: 14pt;
  font-weight: 500;
  font-family: Roboto, sans-serif;
  margin: 2px;
}

.profile-papers-item:hover {
  color: var(--hover-color);
}

.profile-papers-item-active:hover {
  color: var(--hover-color);
}

.analyze-author {
  width: 50%;
}

.analyze-year {
  width: 50%;
}

.analyze-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.closest-item {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: space-between;
}

.analyze-ay {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.analyze-item-link-odd {
  color: var(--color);
  text-decoration: underline;
}

.analyze-item-link-even {
  color: var(--darkest-blue);
  text-decoration: underline;
}

.list-controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.search-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-size: 1.1rem;
}

.search-panel-wrapper {
  width: 800px;
  max-width: 100%;
  margin: auto;
}

.search-file-wrapper {
  display: flex;
  flex-direction: row;
  color: var(--color);
  background-color: var(--darkest-blue);
  align-items: top;
  justify-content: space-between;
  font-size: 1.25rem;
  padding: 1vmin;
  border-radius: 5px;
}

.search-file-clear {
  color: var(--color);
  background-color: var(--darkest-blue);
  border: none;
  padding: 2px;
  border-radius: 5px;
}

.search-file-controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 5vw;
}

.search-header {
  font-size: 1.1rem;
  height: 5vh;
  width: 100%;
}

.search-dnd {
  width: 60vw;
  max-width: 100%;
  margin: 1vh auto;
  padding-top: 4vh;
  padding-bottom: 4vh;
  background: white;
  border: 1px black dashed;
  border-radius: 5px;
}

.select-dnd {
  justify-content: center;
  width: 33%;
  min-width: 200px;
  margin-top: 0.75vh;
  margin-bottom: 0.75vh;
  padding: 3.5vmin;
  border: 1px black dashed;
}

@media screen and (max-width: 1200px) {
  .select-dnd {
    min-width: 100%;
  }
}

.search-button {
  padding-top: 4vh;
  padding-bottom: 4vh;
  margin: auto;
  text-align: center;
}

.upload-papers {
  margin-top: 25px;
}

.splash-wrapper {
  display: flex;
  flex-direction: column;
}

.bug-viewer {
  width: 60vw;
  height: 100%;
  overflow: auto;
}

.logo {
  width: 15vh;
  margin: 5vmin;
}

.crumb {
  color: var(--darkest-blue);
}

.nav-colors {
  color: var(--color);
  background-color: var(--darkest-blue);
}

.centered {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.centered-display {
  margin: auto;
}

.logo-inner {
  width: 100%;
}

.logo-align {
  width: 24vh;
  margin-left: 1vmin;
  margin-top: -2vmin;
  margin-bottom: 2vmin;
}

.base-icon {
  color: var(--color);
}

.bg-wrapper-align {
  width: 100%;
  height: 100%;
  margin-left: 5vmin;
  margin-top: -4vmin;
  margin-bottom: 2vmin;
}

.name {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 10vw;
  height: 16vh;
  max-height: 16vh;
  font-family: "Times New Roman", Times, serif;
}

.sm-caps {
  display: inline;
  font-variant: small-caps;
}

.full {
  font-size: 2vw;
}

.part {
  width: 80%;
  border-radius: var(--border-radius);
  color: var(--color);
  background-color: var(--darkest-blue);
  font-size: 1.5vw;
}

.profile-delete {
  margin-top: 3vh;
  margin-bottom: 3vh;
  padding: 2vh;
  font-size: 1rem;
}

.prompt-delete {
  width: 20vw;
  height: 20vh;
  font-size: 1.5rem;
}

.right-logo {
  height: 15vh;
  padding: 2vmin;
}

.card-title {
  text-decoration: underline;
}

.card-subtitle-wrapper {
  text-align: justify;
}

.card-subtitle {
  display: inline;
}

.card-text-wrapper {
  border-top: 1px solid var(--hover-color);
}

.card-caption {
  display: inline;
  word-wrap: break-word;
}

.cite-data {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.cite-item-head {
  font-weight: bold;
}

.cite-item {
  background-color: var(--alt-color);
  margin: 1px;
}

.cite-caption-wrapper {
  background-color: var(--color) smoke;
  text-align: justify;
  padding: 1vmin;
}

.slider-weight {
  min-width: 5vw;
  max-width: 5vw;
}

.cv-dnd {
  padding-left: 3vw;
  padding-right: 3vw;
  padding-top: 3vh;
  padding-bottom: 3vh;
}

.cv-hover {
  background-color: var(--light-blue);
}

.none-found {
  margin: auto;
  font-size: 28px;
  font-family: var(--sans-serif);
  text-align: center;
  padding-top: 10vh;
}

.paper-upload-wrapper {
  margin-top: 1vmin;
  width: 100%;
}

.paper-edit-controls {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 95%;
}

.paper-wide {
  margin: 0.5vmin;
  min-width: 50%;
  max-width: 75%;
  max-height: 10vh;
}

.paper-narrow {
  margin: 0.5vmin;
  min-width: 12.5%;
}

.paper-main {
  display: flex;
  flex-direction: column;
  padding: 1vmax;
  background-color: white;
  justify-content: space-between;
}

.paper-edit {
  display: flex;
  flex-direction: row;
  border: 1px solid black;
  margin-top: 0.1vmin;
  background-color: var(--alt-color);
}

.empty-papers {
  justify-content: center;
  width: fit-content;
  margin-top: 10%;
  text-align: left;
  font-family: var(--sans-serif);
  font-weight: 500;
  font-size: 2rem;
}


.paper-edit-close {
  color: black;
  height: 3vh;
  width: 3vh;
  font-weight: 600;
  padding-top: 1vh;
  padding-right: 1vh;
}

.paper-space {
  margin-left: 4pt;
}

.paper-container {
  display: flex;
  flex-direction: column;
  background-color: var(--alt-color);
  align-items: center;
  justify-content: center;
  text-align: center;
}

.paper-cite {
  font-family: 'Times New Roman', Times, serif;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  font-size: var(--cite-size);
}

.paper-item {
  margin-bottom: 1vmin;
  box-shadow: 0.15vmin 0.15vmin;
}

.paper-item-upload {
  margin-bottom: 1vmin;
  box-shadow: 0.15vmin 0.15vmin var(--darkest-blue);
}

.half-size {
  min-width: 40%;
  max-width: 50%;
}

.submit-file {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.paper-file {
  padding-left: 3vw;
  padding-right: 3vw;
  padding-top: 3vh;
  padding-bottom: 3vh;
}

.island {
  padding: 1vmax;
}

.paper-missing {
  color: darkred;
  font-size: 10pt;
}

.paper-field {
  max-width: 22%;
  margin: 0.5vmin;
}

.submit-paper {
  padding: 20px;
}

.submitted-item-author-title {
  font-weight: 700;
}

.submit-target {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.submit-target-full {
  display: flex;
  flex-direction: row;
  align-items: top;
  justify-content: space-between;
  width: 90%;
  padding: 1vmin;
}

.submit-paper-item {
  width: 50%;
  margin: 0.5vmin;
}

/* .submit-paper-group {
  margin: auto;
} */

.submitted-item-undecided {
  text-align: center;
  color: black;
  background-color: yellow;
}

.submitted-item-resubmission {
  text-align: center;
  color: black;
  background-color: orange;
}

.submitted-item-offer {
  text-align: center;
  color: black;
  background-color: blue;
}

.submitted-item-declined {
  text-align: center;
  color: black;
  background-color: red;
}

.submitted-item-expedite {
  text-align: center;
  color: black;
  background-color: violet;
}

.submitted-item-withdrawn {
  text-align: center;
  color: black;
  background-color: gray;
}

.submitted-item-accepted {
  text-align: center;
  color: white;
  background-color: green;
}

/* .profile-header {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 15px 40px 30px 40px;
} */

.profile-header-title {
  text-align: left;
  color: var(--dark-blue);
}

.profile-header-subtitle {
  font-weight: 500;
  font-size: calc(24px + (2 * ((100vw - 320px) / 680)));
  font-family: var(--sans-serif);
  color: var(--darkest-blue);
  margin: auto auto 20px 40px;
  text-align: left;
}

.splash-header {
  font-size: 42px;
  font-family: var(--sans-serif);
  color: var(--dark-blue);
}

.profile-input {
  text-align: center;
  padding-right: 30px;
  font-size: 18px;
}

.spaced {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: 1vw;
}

.submit-container {
  background-color: white;
  min-width: 360px;
  max-width: 1500px;
  width: 60%;
  margin: auto;
  box-shadow: var(--box-shadow);
}

.profile-container {
  display: flex;
  flex-direction: column;
  background-color: var(--color);
  //width: 90%;
  //min-height: 75vh;
  padding: 20px;
 // margin: 40px auto 100px auto;
  border-radius: 10px;
  box-shadow: var(--box-shadow);
}



.profile-section-title {
  color: white;
  background-color: var(--dark-blue);
  border-radius: 5px;
  padding: 1.5vmin;
  font-size: 1.5rem;
}

.profile-section {
  min-width: 360px;
  max-width: 600px;
  background-color: var(--color);
}

.standard-section {
  margin: auto;
  padding: 30px 0 30px 0;
  min-width: 360px;
  background-color: var(--color);
}

.profile-item {
  margin: 'auto';
  padding: 10px 0 10px 0;
  min-width: 360px;
  width: 40vw;
}

.profile-check-box {
  margin-bottom: 10px;
}

.section-title {
  color: var(--color);
  background-color: var(--darkest-blue);
  font-size: var(--section-title-size);
  margin-bottom: 1vmin;
}

.filter-title {
  font-size: var(--sub-title-size);
  color: var(--color);
  background-color: var(--darkest-blue);
  width: 100%;
  border-radius: 5px;
  text-align: center;
}


.filter-section-title {
  color: var(--color);
  max-width: 500px;
  background-color: var(--darkest-blue);
  font-size: var(--section-title-size);
  margin-left: auto;
  margin-right: auto;
  margin-top: -0.5vh;
  border-radius: 20px;
}

.reset-header {
  font-family: var(--sans-serif);
  font-size: 1.5em;
  font-weight: 600;
  color: white;
  background-color: var(--dark-blue);
  padding: 1vmin;
  border-radius: 3px;
}

.standard-section-title {
  color: var(--color);
  width: 100%;
  background-color: var(--darkest-blue);
  font-size: 32px;
  font-family: var(--sans-serif);
  text-align: center;
  margin: 40px auto 10px auto;
  border-radius: var(--border-radius);
}

.profile-done {
  margin-top: 1vmin;
}

.standard-section-sxs {
  background-color: lightgray;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-around;
}

.paper-field-toggle {
  font-size: 1rem;
  font-weight: 400;
}

.link-div {
  color: var(--dark-blue);
}


.orcid-btn {
  margin-top: 20px;
  border-radius: 15px;
  padding: 5px 10px 5px 15px;
  background-color: var(--lightest-blue);
  box-shadow: var(--box-shadow-light);
  color: var(--dark-blue);
  cursor: pointer;
}

.orcid-btn:hover {
  box-shadow: var(--box-shadow);
  text-decoration: none;
}

.filtered-column {
  color: var(--dark-blue);
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  padding: 0 10px;
  border: 1px solid var(--dark-blue)
}

.filterable-column {
  color: white;
  background-color: var(--dark-blue);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  padding: 0 10px;
}

.column-header-title {
  font-family: var(--sans-serif);
  font-weight: 600;
  font-size: 1.25em;
  padding: 0 15px;
}

.title-column {
  color: white;
  background-color: var(--dark-blue);
  border-radius: 5px;
  min-width: 250px;
}

.closeable-column {
  color: white;
  background-color: var(--dark-blue);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  padding: 0 10px;
}

.link-div:hover {
  text-decoration: underline;
  cursor: pointer;
}

.profile {
  background-color: lightgray;
}

.profile-card {
  width: 700px;
  max-width: 100%;
  margin: auto;
}

.profile-option {
  font-size: 14pt;
  font-weight: 600;
}

.profile-sub {
  background-color: var(--color);
  text-align: left;
  padding: 1vmax;
  width: 30vw;
  box-shadow: 0.15vmin 0.15vmin;
  margin: 0.5vmin;
}

.profile-orcid {
  padding: 1vmin;
  font-weight: 600;
  text-align: center;
}

.profile-image {
  margin-right: 1vmin;
}

.profile-eth {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.profile-sub-title {
  margin: auto;
  padding-top: 30px;
  border-style: solid transparent;
  border-radius: var(--border-radius);
  font-family: var(--sans-serif);
  font-size: var(--sub-title-size);
  text-align: center;
  color: var(--dark-blue);
}

.profile-sub-title-heading {
  margin-top: 30px;
  text-align: justify;
}

.profile-sub-text {
  font-family: var(--sans-serif);
  font-weight: 600;
  font-size: 18px;
  color: var(--darkest-blue);
  margin: auto;
  padding-top: 20px;
  width: 80vw;
  max-width: 600px;
}

.submit-sub-text {
  justify-self: center;
  text-align: center;
  font-family: var(--sans-serif);
  font-weight: 600;
  font-size: 18px;
  color: var(--darkest-blue);
  margin: auto;
  padding-top: 20px;
  max-width: 80%;
}


.payment-sub-text {
  margin: auto;
  padding-top: 20px;
  font-family: var(--sans-serif);
  font-weight: 600;
  font-size: 18px;
  color: var(--darkest-blue);
  max-width: 50vw;
}

.profile-sub-item {
  font-weight: 600;
  margin-top: 1vmax;
}

.profile-message {
  margin: 1vmin;
  padding: 0.5vmin;
  color: darkred;
  background-color: white;
  font-weight: 600;
  font-size: 20pt;
}

.select-button-panel {
  justify-content: center;
  margin: auto;
  padding-top: 20px;
}

.submit-paper .center-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.analyze-weight {
  min-width: 5vw;
  max-width: 5vw;
  padding: 0.5vmin;
}

.analyze-gender-sort {
  color: green;
}

.analyze-row-odd {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: var(--color);
  background-color: var(--darkest-blue);
}

.analyze-row-even {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: var(--dark-blue);
  background-color: var(--color);
}

.analyze-results-pane {
  margin: 15px;
  width: 95%;
  height: 67vh;
  overflow: auto;
}

.analyze-results-pane-msg {
  margin: 15px;
  width: 95%;
  height: 62vh;
  overflow: auto;
}

.analyze-dnd-message {
  margin: 30px auto 12px;
  width: 60vw;
}

.analyze-title {
  text-align: left;
}

.close-title {
  background-color: var(--darkest-blue);
  color: var(--color);
  padding: 1vmin;
}

.close-items {
  padding: 1vmin;
  background-color: var(--color);
}

.close-item-odd {
  color: var(--color);
  background-color: var(--darkest-blue);
  padding: 1vmin;
}

.close-item-even {
  background-color: var(--color);
  color: var(--darkest-blue);
  padding: 1vmin;
}

.close-item-odd:hover {
  cursor: pointer;
  border: 1px solid var(--hover-color);
}

.close-item-even:hover {
  cursor: pointer;
  border: 1px solid var(--hover-color);
}

.payment-amount {
  font-size: calc(22px + (4 * ((100vw - 320px) / 680)));
  text-align: center;
  display: inline-flex;
}

.payment-amount span {
  padding-left: 10px;
}

.price-item {
  font-size: 16px;
  text-align: left;
}

.price-value {
  font-size: 16pt;
  text-align: right;
  padding-right: 2vw;
}

.list-item {
  color: var(--darkest-blue);
  padding-top: 10px;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: var(--icon-size);
}

.list-item:hover {
  cursor: pointer;
}

.list-item-icon {
  color: var(--dark-green);
  @include m.spread-value(font-size, 1.1em, 1.25em);
  margin-right: 10px;
  text-anchor: start;
}

#remove-item-icon {
  display: flex;
  color: var(--berry);
  margin: auto;
  text-align: center;
  font-size: 22px;
  align-self: center;
}

#disabled-item-icon {
  display: flex;
  color: gray;
  margin: auto 10px auto auto;
  padding-bottom: 10px;
  text-align: center;
  font-size: 20px;
  align-self: center;
}

.list-item-check {
  padding-left: 30px;
  margin-right: -30px;
}

.list-item-missing-icon {
  @include m.spread-value(font-size, 1.1em, 1.25em);
  margin-right: 10px;
  text-anchor: start;
}

.list-item-icon:hover {
  cursor: pointer;
}

#options-icon:hover {
  cursor: auto;
}

.list-item-detail {
  border-radius: 15px;
  border: none;
  @include m.spread-value(font-size, 12px, 16px);
  margin: auto;
  padding: 1px 6px 1px 6px;
  width: fit-content;
  color: var(--color);
  font-weight: 600;
}

.lid-yellow {
  background-color: var(--yellow);
}

.lid-green {
  background-color: var(--dark-green);
}

.warning-close {
  padding: 1vmin;
}

.delete-panel {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.delete-button {
  color: var(--color);
  background-color: var(--darkest-blue);
  padding: 1vmin;
  margin-top: 1vmax;
  margin-bottom: 1vmax;
  width: 10vmin;
  align-items: center;
  justify-content: center;
}

.delete-button:hover {
  color: black;
  text-decoration: underline;
  cursor: pointer;
}

.cancel-payment-plan {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.cancel-payment-plan-button {
  color: var(--color);
  background-color: var(--darkest-blue);
  padding: 1vmin;
  margin-top: 1vmax;
  margin-bottom: 1vmax;
  width: 10vmin;
  align-items: center;
  justify-content: center;
}

.cancel-payment-plan-button:hover {
  color: black;
  text-decoration: underline;
  cursor: pointer;
}

.cdp-panel {
  background: black;
  padding: 3vmin;
}

.cdp-header {
  color: var(--color);
  font-size: xx-large;
  background-color: var(--darkest-blue);
  padding: 1vmax;
}

.cdp-input-panel {
  display: flex;
  flex-direction: column;
  margin: 2vmin;
  padding: 1vmin;
  font-size: large;
  background-color: var(--alt-color);
}

.cdp-text-panel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.cdp-text {
  width: 100%;
  border: none;
}

.cdp-button-panel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.cdp-remove-files-panel {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.cdp-check {
  margin: 1vmin;
}

.cdp-button {
  padding-top: 1vmin;
  padding-bottom: 1vmin;
  padding-left: 2vmin;
  padding-right: 2vmin;
  color: var(--color);
  background-color: var(--darkest-blue);
}

.sidebar-card {
  width: 20vw;
}

.slice-tip {
  background-color: var(--color);
  width: 20vw;
  height: 40vh;
}

.slice-title {
  color: var(--color);
  background-color: var(--darkest-blue);
}

.profile-subs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.payment-button {
  padding: 1vmin;
  width: 20vw;
  align-items: center;
  justify-content: center;
}

.payment-form {
  width: 30vw;
  align-items: left;
  justify-content: left;
}

@media screen and (max-width: 767px) {
  .display-wrapper {
    flex-direction: column;
  }

  .profile-subs {
    width: 100%;
  }

  .feature-header {
    width: 0;
  }

  /*   .display-panel {
    margin-top: -5vh;
    width: 100%;
    padding: 2vh 4vw;
  }  */

  .display-panel-loggedin {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1vmin;
    padding-right: 1vmin;
  }

  .display-column {
    height: auto;
    width: auto;
  }

  .display-right {
    display: none;
    height: 0px;
  }

  .command-menu {
    padding: 1.5vmax;
  }

  .command-panel {
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    flex-flow: row-reverse;
    width: auto;
    height: auto;
    margin-top: 1vh;
    margin-bottom: 1vh;
    padding: 2vh 4vw;
  }

  .command-item {
    display: none;
    text-decoration: none;
  }

  .command-item-active {
    width: 45vw;
    margin-top: .1vmin;
    text-decoration: none;
  }

  .open-command-panel {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .command-active-sub-item {
    width: 45vw;
    text-decoration: none;
  }

  .command-item-opened {
    background-color: var(--darkest-blue);
    color: var(--color);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px,
      rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
    padding: 10px;
    border-radius: var(--border-radius);
    border: 1px solid transparent;
    font-size: 14pt;
    font-weight: 500;
    font-family: Roboto, sans-serif;
    width: 45vw;
    margin-bottom: 0.2vmin;
  }

  .command-sub-item-opened {
    background-color: var(--alt-color);
    color: var(--hover-color);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px,
      rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
    padding: 10px;
    border-radius: var(--border-radius);
    border: 1px solid transparent;
    font-size: 14pt;
    font-weight: 500;
    font-family: Roboto, sans-serif;
    width: 45vw;
    margin: 2px;
  }

  .command-icon {
    padding: 1.5vmax;
  }

  .twitter-panel {
    width: 0vw;
    max-height: 0vh;
  }

  .bg {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 1vmin;
  }

  .bg-item {
    margin: 2px;
  }

  .bg-wrapper {
    width: 80vw;
  }

  .nav-bg-msg {
    font-size: 0.75em;
    padding: none;
  }

  .nbar {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
    height: auto;
  }

  .right-logo {
    width: 80vw;
    padding: 0;
    margin-bottom: 1vmin;
  }

  .left-logo {
    display: none;
  }


  .signon-item {
    width: 100%;
  }

  .signon-button {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .msft-signon-item {
    width: 100%;
    border-radius: 5px;
  }

  .login-box {
    width: 100%;
    height: fit-content;
  }

  .login-item {
    width: 100%;
  }

  .login-image {
    width: 100%;
    padding-bottom: 1vh;
  }


  .standard-section-sxs {
    flex-direction: column;
    width: 100%;
  }

  .profile-sub {
    width: 100%;
  }

  .paper-edit {
    flex-direction: column;
  }

  .paper-field {
    width: 98%;
    max-width: 100%;
  }

  .paper-wide {
    width: 98%;
    max-width: 100%;
  }

  .paper-narrow {
    width: 98%;
    max-width: 100%;
  }

  .paper-edit-close {
    margin-left: 95%;
  }

  .paper-status {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .list-item {
    font-size: 12pt;
  }

  .search-header {
    width: 100%;
  }

  .split-results {
    height: 30vh;
    margin-bottom: 1vh;
  }

  /*   .author-list-wrapper {
    width: 40vw;
    max-height: 400px;
    overflow-y: ;
  } */

  /*   .author-list-viewer {
    font-size: 1.1rem;
    height: 350px;
    overflow-y: scroll;
} */

  .combined-list-item {
    flex-direction: column;
    justify-content: center;
  }

  .analyze-weight {
    min-width: 25vw;
    max-width: 25vw;
  }

  .command-sub-item {
    display: none;
  }

  .wsva {
    height: 6.5vh;
    font-size: 18pt;
  }

  .wsvb {
    font-size: 18pt;
    height: 6.5vh;
  }

  /*   .submit-paper {
    margin: auto;
    width: 100%;
  } */

  .submit-target {
    flex-direction: column;
    width: 100%;
  }

  /*   .submit-paper-group {
    width: 100%;
  } */

  .submit-paper-item {
    width: 100%;
  }
}

.checkout-button:hover {
  transition-duration: 100ms;
  box-shadow: 5px 5px 5px #EDEBE9;
}

.checkout-modal {
  max-width: 100%;
  max-height: 100%;
  width: 500px;
  height: fit-content;
  padding: 10px 0 20px 0;
  margin: auto;
  background-color: inherit;
}


.loader {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.loader div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: var(--dark-blue);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.loader div:nth-child(1) {
  left: 8px;
  animation: loader1 0.6s infinite;
}

.loader div:nth-child(2) {
  left: 8px;
  animation: loader2 0.6s infinite;
}

.loader div:nth-child(3) {
  left: 32px;
  animation: loader2 0.6s infinite;
}

.loader div:nth-child(4) {
  left: 56px;
  animation: loader3 0.6s infinite;
}

@keyframes loader1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes loader3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes loader2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}
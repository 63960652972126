.wrapper {
    display: flex;
    flex-direction: column;
    width: 750px;
    font-family: 'Outfit', sans-serif;
}

.tabs {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap-reverse;
}

@media screen and (max-width: 750px) {
    .wrapper {
        width: 100%;
        //position: relative;
    }
}


.tab {
    cursor: pointer;
    padding: 10px 20px;
    margin: 0 1px -5px 0;
    background: var(--violet);
    display: inline-block;
    color: #fff;
    font-weight: 600;
    border-radius: 7px 7px 0px 0;
    border-style: none;
}

.tab:hover {
    background: var(--blue);
}

#active-tab {
    background-color: #fff;
    color: var(--violet);
    font-weight: 800;
    border-radius: 7px 7px 0px 0px;
    border-style: none;
}


#active-panel {
    border-radius: 0px 4px 4px 4px;
}


.log {
    padding: 0 20px 0px 35px;
    background-color: #fff;
    max-width: 100%;
    height: calc(100% - 4.25em);
    position: fixed;
    right: 0;
    top: 4em;
    font-family: 'Courier New', Courier, monospace;
    font-weight: 400;
    font-size: 17px;
    color: #000;
    z-index: 1000;
}

.close {
    position: absolute;
    top: 49%;
    left: 5px;
    font-weight: 800;
    cursor: pointer;
}

.close:hover {
    transform: translate(10%, 0);
}


.field-wrapper {
    background-color: #f8f8fb;
    padding: 20px 7px 7px 10px;
    height: 100%;
    border-radius: var(--border-radius);
    box-shadow: inset 1px 1px 10px 2px #eeeded;
    overflow-y: auto;
    overflow-x: hidden;
}

#text-error {
    color: #ff0000;
    font-weight: 600;
}

#text-success {
    font-weight: 600;
    color: var(--green);
}

#text-label {
    font-weight: 600;
    color: var(--violet);
}

#text-warning {
    color: var(--yellow);
    font-weight: 600;
}

.panel-title {
    text-align: center;
}
.search-dnd {
    width: 800px;
    max-width: 100%;
    margin: 1vh auto;
    padding-top: 4vh;
    padding-bottom: 4vh;
    background: white;
    border: 1px black dashed;
    border-radius: 5px;
}

.search-dnd-field {
    padding-top: 4vh;
    padding-bottom: 4vh;
    margin: auto;
    text-align: center;
}

.search-dnd-btn {
    color: var(--color);
    font-size: 14pt;
    font-family: var(--sans-serif);
    background-color: var(--dark-blue);
    border-radius: var(--border-radius);
    cursor: pointer;
    padding-top: 1vmax;
    padding-bottom: 1vmax;
    padding-right: 1.75vmax;
    padding-left: 1.75vmax;
}

@media screen and (min-width: 1600px) {
    .search-dnd-btn {
        font-size: 16pt;
    }

    .search-file-box {
        font-size: 16pt;
    }
}

.search-file-box {
    display: flex;
    flex-direction: row;
    background-color: var(--darkest-blue);
    width: 100%;
    border-radius: 5px;
    color: var(--color);
    font-size: 14pt;
    justify-content: space-between;
}

.search-dnd-btn:hover {
    background-color: var(--violet);
    box-shadow: var(--box-shadow-light);
}

.search-file-box:hover {
    background-color: var(--berry);
    cursor: pointer;
    box-shadow: var(--box-shadow-light);

}

.search-file-cancel {
    color: var(--color);
    border: none;
    padding: 0 5px 10px 0;
    margin-top: -2px;
    border-radius: 5px;
}

.search-file-cancel:hover {
    background-color: transparent;
    color: var(--color);
    cursor: pointer;
}